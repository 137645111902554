@import "theme";

.categories-main-fluid {
  background: $black-color;
  padding: 60px 0;

  .categories-row-heading {
    margin-bottom: 45px;

    h1 {
      color: var(--colors-white, #fff);
      font-size: 42px;
      font-family: Oswald, sans-serif;
      font-weight: 600;
      text-transform: uppercase;
    }

    ul {
      li {
        &.list-inline-item:not(:last-child) {
          margin-right: 1rem;
        }
      }
    }
  }

  .categories-row-card.categories-row-card-list {
    margin-bottom: 30px;
    align-items: stretch;
    .category-img-div {
      img.img-fluid {
        height: 100%;
        border-radius: 12px;
        width: 100%;
        object-fit: cover;
        max-height: 230px;
      }
    }
    .category-QR-div {
      align-items: center;
      justify-content: flex-end;
    }

    .category-details-div {
      padding: 0 45px 0 15px;
      h5 {
        color: $white-color;
        font-size: 28px;
        font-family: Oswald, sans-serif;
        font-weight: 600;
        letter-spacing: -0.3px;
        text-transform: uppercase;
        display: inline-block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 100%;
      }

      p {
        color: rgba(212, 212, 212, 0.9);
        font-size: 19px;
        line-height: 160%;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        display: -webkit-box;
        margin-bottom: 0px;
      }
      .read-more-btn {
        color: $theme-color;
        font-size: 19px;
        margin-bottom: 1rem;
      }

      .product-details-inner {
        ul {
          padding-bottom: 10px;
          li {
            color: $theme-secondary;
            font-size: 16px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 100%;
            max-width: 60%;
            &.list-inline-item:not(:last-child) {
              color: $white-color;
              width: 80px;
            }
          }
        }
      }
    }

    .category-QR-div {
      img {
        cursor: pointer;
        width: 166px;
        height: 167px;
        object-fit: contain;
      }
    }
    &.categories-row-grid {
      .category-img-div {
        img {
          height: 260px;
        }
      }
      .category-details-div {
        padding: 15px 5px 0;
      }
      .category-QR-div {
        position: absolute;
        bottom: 15px;
        right: 15px;
        img {
          cursor: pointer;
          width: 56px;
          height: 56px;
        }
      }
    }
  }

  .categories-row-card.categories-row-grid {
    margin-bottom: 30px;
    align-items: stretch;
    .category-img-div {
      img {
        height: 100%;
        border-radius: 12px;
        width: 100%;
        object-fit: cover;
      }
    }
    .category-QR-div {
      align-items: center;
      justify-content: flex-end;
    }

    .category-details-div {
      padding: 0 45px 0 15px;
      h5 {
        color: $white-color;
        font-size: 28px;
        font-family: Oswald, sans-serif;
        font-weight: 600;
        letter-spacing: -0.3px;
        text-transform: uppercase;
        display: inline-block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 100%;
      }

      p {
        color: rgba(212, 212, 212, 0.9);
        font-size: 19px;
        line-height: 160%;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        display: -webkit-box;
        margin-bottom: 0px;
      }
      .read-more-btn {
        color: $theme-color;
        font-size: 19px;
        margin-bottom: 1rem;
      }

      .product-details-inner {
        ul {
          // padding-bottom: 10px;
          li {
            color: $theme-secondary;
            font-size: 16px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            &.list-inline-item:not(:last-child) {
              color: $white-color;
              width: 80px;
            }
          }
        }
      }
    }

    .category-QR-div {
      img {
        cursor: pointer;
        width: 166px;
        height: 167px;
      }
    }
    &.categories-row-grid {
      .category-img-div {
        img {
          height: 260px;
        }
      }
      .category-details-div {
        padding: 15px 5px 0;
      }
      .category-QR-div {
        position: absolute;
        bottom: 15px;
        right: 15px;
        img {
          cursor: pointer;
          width: 56px;
          height: 56px;
        }
      }
    }
  }
}
