.ant-select-single .ant-select-selector .ant-select-selection-item {
  display: flex;
  align-items: center;
}

.category-list-btn {
  padding: 10px 20px !important;
  height: unset !important;
  background: #51545e82;
  font-weight: 400 !important;
  font-family: "Manrope", sans-serif !important;
  font-size: 18px !important;
  border: none;
  &:hover {
    background: #afff06 !important;
    border-color: #afff06 !important;
    color: #111111 !important;
    font-weight: 400 !important;
  }
  &.active {
    background: #afff06 !important;
    border-color: #afff06 !important;
    color: #111111 !important;
    font-weight: 400 !important;
  }
  &.done {
    background: #0093ff !important;
    border-color: #0093ff !important;
    color: #ffffff !important;
    font-weight: 400 !important;
    &.active {
      background: #afff06 !important;
      border-color: #afff06 !important;
      color: #111111 !important;
      font-weight: 400 !important;
    }
    &:after {
      content: "";
      display: inline-block;
      width: 22px;
      height: 21px;
      background-image: url("data:image/svg+xml,%3Csvg width='20' height='21' viewBox='0 0 20 21' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M10 20.5C15.5228 20.5 20 16.0228 20 10.5C20 4.97715 15.5228 0.5 10 0.5C4.47715 0.5 0 4.97715 0 10.5C0 16.0228 4.47715 20.5 10 20.5ZM15.5588 8.00027C15.8351 7.69167 15.8089 7.21752 15.5003 6.94123C15.1917 6.66493 14.7175 6.69113 14.4412 6.99973L11.0721 10.7629C10.3894 11.5254 9.9296 12.0363 9.53652 12.3667C9.16207 12.6814 8.94213 12.75 8.75 12.75C8.55787 12.75 8.33794 12.6814 7.96348 12.3667C7.5704 12.0363 7.11064 11.5254 6.42794 10.7629L5.55877 9.79209C5.28248 9.48349 4.80833 9.4573 4.49973 9.73359C4.19113 10.0099 4.16493 10.484 4.44123 10.7926L5.34753 11.8049C5.98338 12.5152 6.51374 13.1076 6.99835 13.5149C7.51099 13.9458 8.06393 14.25 8.75 14.25C9.43607 14.25 9.98901 13.9458 10.5016 13.5149C10.9863 13.1076 11.5166 12.5152 12.1525 11.8049L15.5588 8.00027Z' fill='white'/%3E%3C/svg%3E%0A");
      background-repeat: no-repeat;
      top: 5px;
      right: -5px;
      position: relative;
      margin: 0px 0px 0px 8px;
    }
  }
}

.car-image-upload-container {
  position: relative;
  overflow: hidden;
  display: inline-block;
  text-align: center;
  max-width: 250px;
  margin: 0 auto;
  .image-Upload-btn {
    height: 56px;
    padding: 10px 25px;
    border: 1px solid #ffffff;
    font-size: 18px;
    color: #ffffff;
    font-weight: 500;
    background: transparent;
    font-family: "Manrope";
  }
}
.car-image-upload-container input[type="file"] {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  width: 230px;
  z-index: 11;
  height: 56px;
  padding: 10px 25px;
  border: 1px solid #ffffff;
  font-size: 18px;
}

.part-overlay {
  width: 100%;
  height: 100%;
  top: 0;
  display: flex;
  background: #181a1fcc;
  justify-content: center;
}
.add-car-form.ant-form {
  .ant-form-item {
    .ant-form-item-row {
      .ant-form-item-label {
        label {
          color: #fff !important;
        }
      }
    }
  }
}
