@import "theme";

.custom-header-main {
  background: $theme-color;
  height: 100px;

  .nav-menu-list {
    li {
      &.list-inline-item:not(:last-child) {
        margin-right: 5rem;
        @include screen("tab") {
          margin-right: 3rem;
        }
      }
      &.list-inline-item:nth-child(2) {
        margin-right: 2.5rem;
      }

      a {
        font-size: 22px;
        line-height: 30px;
        font-weight: 500;
        font-family: Oswald, "sans-serif";
        color: #fff;

        &.active,
        &:hover {
          color: $theme-secondary;
          border: none;
        }
      }
    }
  }

  .auth-btn-ul {
    li {
      .ant-btn {
        height: 54px;
        width: 140px;
        padding: 10px 15px;
        border-radius: 80px;
        font-size: 20px;
        line-height: 30px;
        font-family: Oswald, "sans-serif";
        border: 2px solid $white-color;
        color: $white-color;
        background: transparent;
        font-weight: 600;
        &.sign-in-btn {
          &:hover {
            border-color: $black-color;
            background: $black-color;
          }
        }

        &.sign-up-btn {
          border-color: $black-color;
          background: $black-color;
          color: $white-color;

          &:hover {
            background: transparent;
            border-color: $white-color;
          }
        }
      }
    }
  }
}
.sidebar-custom {
  display: none;
}
.profile-details-btn.ant-btn {
  background: transparent;
  border: 3px solid $theme-secondary;
  width: 66px;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 66px;
  border-radius: 50%;
  margin-left: 10px;
  img {
    width: 58px;
    height: 58px;
    border-radius: 50%;
    object-fit: cover;
    @include screen("tab") {
      width: 44px;
      height: 44px;
    }
  }
  &:hover,
  &:focus {
    outline: 0;
    box-shadow: none;
    border: 3px solid $theme-secondary;
  }
}

.menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  opacity: 1;
  transition: opacity 0.6s ease;
  -webkit-transform: translate3d(0, -100px, 0);
  transform: translate3d(0, -100px, 0);
}

.menu.reveal {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}

.menu.hide {
  opacity: 0;
}
