@import "theme";

.faq-heading-fluid {
  background: $black-color;
  padding: 60px 0 30px;

  h1 {
    color: $grasS;
    font-size: 64px;
    font-family: Oswald, sans-serif;
    font-weight: 700;
    line-height: 130%;
    text-transform: uppercase;

    .shadow-text {
      color: $white-color;
      text-shadow: 0 36px 44px $black-color;
      display: inline-block;
      width: 100%;
    }

    .theme-secondary-text {
      color: $theme-secondary;
    }
  }
}

.faq-banner-fluid {
  padding: 0;

  img {
    width: 100%;
  }
}

.faq-details-fluid {
  padding: 75px 0 12%;
  background: $black-color;
  overflow-y: hidden;

  .faq-details-row {
    border-top: 1px solid $white-gray-rgba;
    padding: 30px 0;
    // width: 1343px;

    h5 {
      color: $theme-secondary;
      font-size: 27px;
      font-family: Oswald, sans-serif;
      font-weight: 500;
      letter-spacing: -0.54px;
      text-transform: uppercase;
      margin-bottom: 15px;

      img {
        width: 10px;
        height: 13px;
        margin-right: 15px;
      }
    }

    p,
    ul li {
      color: rgba(212, 212, 212, 0.8);
      font-size: 19px;
      font-weight: 300;
      line-height: 1.7;
    }

    p {
      margin-bottom: 10px;
    }

    ul {
      li {
        &:nth-child(1) {
          a {
            color: $theme-secondary;
            font-weight: 500;
            text-decoration: underline;
          }
        }
      }
    }

    h6 {
      color: $theme-color;
      font-size: 19px;
      font-weight: 600;
      line-height: 1.7;
    }

    &:last-child {
      border-bottom: 1px solid $white-gray-rgba;
    }
  }

  .add-vehicle-row {
    padding: 90px 0 0;

    h2 {
      color: $white-color;
      text-align: center;
      font-size: 48px;
      font-family: Oswald, sans-serif;
      font-weight: 700;
      line-height: 130%;

      span {
        color: $theme-color;
      }
    }

    p {
      color: $grasS;
      text-align: center;
      font-size: 19px;
      font-weight: 400;
      line-height: 170%;
      opacity: 0.7;
      margin: 0px 0px 30px 0;
    }

    h3 {
      color: $theme-secondary;
      text-align: center;
      font-size: 28px;
      font-weight: 800;
      line-height: 140%;
    }
  }

  .project-overview-row {
    padding: 60px 0 30px;

    .project-demo-card {
      height: 530px;

      .project-img {
        overflow: hidden;
        height: 355px;
      }

      .project-demo-details {
        padding-top: 20px;

        h5 {
          color: $white-color;
          font-size: 20px;
          font-weight: 700;
          line-height: 150%;
        }

        p {
          color: $theme-secondary;
          font-size: 16px;
          font-weight: 500;
          line-height: 150%;
          margin: 10px 0 5px;
          opacity: 1;
        }

        span {
          color: $white-color;
          font-size: 14px;
          font-weight: 500;
          line-height: 150%;
          display: inline-block;
          width: 100%;
        }
      }
    }
  }

  .more-details-demo {
    p {
      margin: 0 0 20px;
      font-size: 16px;
      opacity: 1;
      font-weight: 400;

      a {
        color: $theme-secondary;
        text-decoration: underline;
        font-weight: 700;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .picture-app-flow-bg {
    background: url("../images/few-picture-mobile-app-flow.png");
    background-size: 55%;
    background-repeat: no-repeat;
    background-position-x: right;
    background-position-y: top;
    min-height: 1040px;
    @include screen("desktop") {
      min-height: 800px;
    }
    @include screen("tab") {
      background: none;
      margin-top: 20px;
      min-height: 100%;
    }
  }

  .few-picture-row {
    margin-top: -45px;
    min-height: 1040px;
    height: 100%;
    @include screen("desktop") {
      min-height: 800px;
    }
    @include screen("tab") {
      height: auto;
      min-height: 100%;
    }
    h2 {
      font-size: 65px;
      font-family: Oswald, sans-serif;
      font-weight: 600;
      line-height: 122%;
      letter-spacing: -0.63px;
      color: $white-color;
      margin-bottom: 20px;

      span {
        color: $theme-color;
      }
    }

    p {
      color: $grasS;
      font-size: 20px;
      font-weight: 400;
      line-height: 170%;
      opacity: 0.8;
    }

    .picture-app-flow-div {
      img {
        position: relative;
        right: 30px;
        max-width: 850px;
        width: 850px;
        top: 70px;
      }
    }
  }

  &.faq-des-fluid {
    padding-top: 13%;
    margin-top: -130px;
    padding-bottom: 90px;

    .signup-btn-div {
      padding-top: 30px;

      .ant-btn {
        height: 60px;
        background: $theme-color;
        border-radius: 8px;
        padding: 16px 56px;
        border: 1px solid $theme-color;
        color: $white-color;
        font-size: 20px;
        font-weight: 600;
      }
    }

    .border-bottom-details {
      border-bottom: 1px solid $white-gray-rgba;
    }

    .add-vehicle-row {
      ul {
        display: flex;
        align-items: center;
        justify-content: center;

        li {
          color: $theme-secondary;
          font-size: 18px;
          font-family: Oswald, sans-serif;
          font-weight: 500;
          line-height: 170%;
          letter-spacing: 1.35px;
          text-transform: uppercase;
        }
      }
    }

    .project-overview-update {
      padding: 10% 0 0;

      .project-demo-update {
        height: auto;

        &.demo-row-1 {
          position: relative;
          top: -60px;
        }

        .project-img {
          height: 100%;
        }

        .project-demo-details {
          background: #0d0d0d;
          width: 89%;
          margin: auto;
          padding: 15px 10px 30px;

          p {
            color: #f1f1f1;
            font-size: 15px;
            font-weight: 400;
          }
        }
      }
    }

    .regards-row-main {
      padding: 30px 0 90px;

      .regards-big-img {
        img {
          height: 645px;
          width: 100%;
          border-radius: 24px;
        }
      }

      .regards-details-row {
        position: absolute;
        right: 0;
        top: 0;
        padding-top: 45px;
        width: 114%;

        p {
          color: rgba(212, 212, 212, 0.8);
          font-size: 17px;
          font-weight: 400;
          line-height: 28px;

          a {
            color: $theme-color;
          }
        }

        .regards-sign-div {
          h6 {
            color: $white-color;
            font-size: 16px;
            font-weight: 600;
            line-height: 170%;
          }

          img {
            height: 67px;
          }
        }
      }
    }
  }
}

.faq-documentation-fluid {
  background: url("../images/documention-details-bg-img.png") no-repeat;
  background-color: #000;
  background-size: cover;
  padding: 21% 0;
  margin-top: -180px;
  position: relative;
  z-index: 1;
  @include screen("mobile") {
    padding: 55% 0 14%;
    margin-top: -32px;
  }

  .document-heading {
    padding-bottom: 45px;

    h3 {
      color: $white-color;
      font-size: 53px;
      font-family: Oswald, sans-serif;
      font-weight: 700;
      line-height: 127%;
      letter-spacing: -0.53px;
      margin-bottom: 20px;

      span {
        color: $theme-color;
      }
    }

    p {
      color: $grasS;

      font-size: 19px;
      font-weight: 500;
      line-height: 170%;
    }
  }

  .document-details-inner {
    .docs-section-details {
      width: 38%;

      ul {
        li {
          color: $grasS;
          font-size: 18px;
          font-weight: 400;
          line-height: 170%;
          padding-bottom: 45px;
          span {
            color: $theme-secondary;
            font-weight: 800;
          }
        }
      }
    }

    .docs-section-img-div {
      width: 24%;
      padding: 0 15px;
    }
  }
}

.app-flow-description {
  p {
    color: $grasS;
    font-size: 18px;
    font-weight: 400;
    line-height: 170%;
    opacity: 0.8;
    @include screen("mobile") {
      font-size: 15px;
    }
  }
}
