.navbar_container {
  position: sticky;
  top: 0;
  background-color: blue;
  height: 100px;
}
.mechanics_container {
  display: flex;
  flex-direction: column;
  background-color: black;
  width: 100%;
  min-height: 100vh;
  overflow-y: auto;
}

.mechanics-btn {
  padding: 10px;
  cursor: pointer;
  background-color: #0093ff;
  color: #fff;
  border-radius: 5px;
  font-weight: 600;
  border: none;
}

.first-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1.75rem 0rem 2rem 0rem;
}

.first-content .heading {
  font-weight: 600;
  font-size: 42px;
  color: #fff;
}

.mechanics-remove {
  justify-content: space-evenly;
  display: flex;
  padding: 10px 25px;
  cursor: pointer;
  background: transparent;
  color: #fff;
  border-radius: 25px;
  color: #ff4f4a;
  font-weight: 600;
  font-size: 17px;
  border: 1px solid #ff4f4a;
  align-items: center;
}
.inner-box-content {
  font-size: 20px;
}
.second-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.5rem;
  background-color: #292b30;
  border-radius: 10px;
  color: #fff;
  margin: 1rem 0rem;
}
.title-content {
  font-weight: 500 !important;
}
.number-content {
  font-size: 16px;
  color: #9be206;
  font-weight: 500;
  margin-top: 5px;
}

.mechanics-title {
  font-weight: 800;
  font-size: 48px;
  color: #fff;
  margin-top: 2rem;
  margin-bottom: 0.5rem;
}
.mechanics-sub-title {
  color: #d4d4d4;
  font-size: 1rem;
  opacity: 0.7;
}
