@import "theme";

.home-main-banner-fluid {
  background: $black-color;
  padding: 13% 30px 60px 0;
  @include screen("tab") {
    padding: 13% 0 0 0;
  }
  @include screen("mobile") {
    padding: 13% 0px;
  }

  .slider-div-home {
    .overlay-shadow {
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(
        270deg,
        #000,
        transparent,
        transparent,
        transparent,
        transparent,
        transparent
      );
      z-index: 9;
    }

    .slider-counter-div {
      top: -110px;
      left: 45px;

      h6 {
        color: $grasS;
        font-size: 17px;
        font-weight: 500;
        line-height: 27px;
      }

      ul {
        display: flex;
        align-items: center;

        li {
          display: flex;
          align-items: center;
          color: $grasS;
          font-size: 20px;
          font-family: Oswald, "sans-serif";
          font-weight: 700;
          text-transform: uppercase;

          span {
            color: $theme-color;
          }
          .ant-btn {
            height: auto;
          }
        }
      }
    }
  }

  .right-side-content-div {
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-width: 680px;
    h1 {
      font-size: 68px;
      font-family: Oswald, "sans-serif";
      font-weight: 600;
      line-height: 120%;
      letter-spacing: -1.56px;
      text-transform: uppercase;
      color: #ffffff;
      position: relative;
      right: 10%;
      z-index: 9;
      top: -120px;
      width: 80%;
      span {
        color: $theme-color;
      }
      @include screen("laptop") {
        font-size: 68px;
        width: 100%;
      }
      @include screen("tab") {
        font-size: 46px;
        right: 0;
        top: 0;
        width: 100%;
        padding-top: 30px;
      }
      @include screen("mobile") {
        font-size: 30px;
        right: 0;
        top: 0;
        width: 100%;
        padding-top: 30px;
      }
    }
    .app-link {
      margin-top: 60px;
      margin-right: 30px;
      @include screen("tab") {
        margin-left: auto;
        margin-right: auto;
      }
      li {
        button {
          img {
            @include screen("tab") {
              max-width: 200px;
            }
            @include screen("mobile") {
              max-width: 100px;
            }
          }
        }
      }
      @include screen("desktop") {
        margin-top: -60px;
      }
      @include screen("tab") {
        margin-top: 0px;
      }
      a {
        &.list-inline-item:not(:last-child) {
          margin-right: 1rem;
        }

        .ant-btn {
          height: auto;
        }
      }
    }
    .social-media-link {
      margin-top: 4rem;
      margin-bottom: 0.5rem;
      @include screen("laptop") {
        margin-right: 0px;
      }
      a {
        color: #999999;
        font-size: 18px;
        font-weight: 400;
        margin-right: 32px;
        &:hover {
          color: #999999;
        }
        &:last-child {
          margin-left: 0px;
        }
        @include screen("tab") {
          &:last-child {
            margin-right: 0px;
          }
        }
        @include screen("mobile") {
          font-size: 14px;
        }
      }
      @include screen("tab") {
        margin-top: 1rem;
        margin-right: 0px;
        flex-direction: row !important;
        justify-content: center;
      }
    }
  }
}

.right-tool-for-job-fluid {
  background: $black-color;
  padding: 90px 0 60px;
  @include screen("tab") {
    padding: 25px 0 60px;
  }

  .jobs-des {
    position: absolute;
    top: -45px;
    left: 60px;
    width: 40%;
    @include screen("tab") {
      position: inherit;
      width: 100%;
      margin-bottom: 40px;
      text-align: center;
    }
    p {
      color: $grasS;
      font-size: 20px;
      font-weight: 300;
      line-height: 1.5;
      opacity: 0.7;
      @include screen("tab") {
        font-size: 18px;
      }
      @include screen("mobile") {
        font-size: 16px;
        text-align: center;
      }
    }
  }

  .right-tool-img-div {
    padding-bottom: 60px;
    text-align: center;
    max-width: 963.07px;
    width: 100%;
    margin-top: -20px;
    margin-left: auto;
    margin-right: auto;
  }

  .tools-job-details {
    padding: 30px 0;

    h2 {
      color: $white-color;
      font-size: 78px;
      font-family: Oswald, sans-serif;
      font-weight: 700;
      letter-spacing: -1.56px;
      text-transform: uppercase;
      @include screen("laptop") {
        font-size: 68px;
      }

      span {
        color: $theme-color;
      }
    }
    .ant-collapse-header-text {
      p.title {
        font-size: 24px;
        font-weight: 400 !important;
        color: #fff;
        @include screen("laptop") {
          font-size: 20px;
        }
        @include screen("tab") {
          font-size: 14px;
        }
      }
    }
    p {
      color: $grasS;
      font-size: 20px;
      font-weight: 300;
      line-height: 180%;
      opacity: 0.7;
      @include screen("laptop") {
        font-size: 18px;
      }
    }
  }

  .job-details-card {
    background: #181a1f;
    width: 777px;

    .job-details-img-div {
      padding: 0;

      img {
        height: 442px;
        border: 0px, 0px, 1px, 0px;
        padding: 8px, 24px, 8px, 24px;
      }
    }

    .job-details-list {
      padding: 0;

      li {
        color: $white-color;
        font-size: 24px;
        font-family: Oswald, sans-serif;
        line-height: 140%;
        letter-spacing: -0.24px;
        text-transform: uppercase;
        border-bottom: 1px solid rgba(255, 255, 255, 0.2);
        height: 83px;
        display: flex;
        align-items: center;
        padding: 0 20px;

        img {
          margin-right: 15px;
        }

        &:last-child {
          border: none;
        }
      }
    }
  }
}

.how-to-accordion {
  border-radius: 0px;
  .ant-collapse-item-active {
    border: none;
  }
  .ant-collapse-header[aria-expanded="true"] {
    visibility: hidden;
    height: 0;
    padding: 0px !important;
  }
  .ant-collapse-item {
    background: #181a20;
    &:last-child {
      border-radius: 0px;
    }
    .ant-collapse-header {
      .ant-collapse-header-text {
        color: $white-color;
        font-size: 24px;
        font-family: Oswald, sans-serif;
        line-height: 140%;
        letter-spacing: -0.24px;
        text-transform: uppercase;
        opacity: 1;
        display: flex;
        align-items: center;
        padding: 10px 10px;
        @include screen("mobile") {
          padding: 0px;
          img {
            width: 25px;
          }
        }
      }
    }
    .ant-collapse-content-box {
      padding: 0px;
    }
  }
}

.accord-body {
  @include screen("laptop") {
    padding: 10px 10px 0px;
  }
  p.title {
    font-family: Oswald;
    font-size: 30px;
    font-weight: 600;
    line-height: 1.5;
    letter-spacing: -0.02em;
    text-align: left;
    color: #fff;
    text-transform: uppercase;
    opacity: 1;
    @include screen("tab") {
      font-size: 20px;
    }
  }
  img.p-img {
    width: 250px;
    // margin-bottom: -180px;
    margin-bottom: 40px;
    @include screen("mobile") {
      width: 130px;
    }
  }
}

.community-slider-data {
  @include screen("tab") {
    padding-left: 10px;
    padding-right: 10px;
  }
}

.automotive-digital-documentation-details {
  padding-top: 60px;

  .automotive-digital-img-div {
    max-width: 733px;
    width: 100%;

    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(260deg, #000 0%, rgba(0, 0, 0, 0) 35%);
      z-index: 1;
    }

    .trusted-shops-worldwide-div {
      border-radius: 10px;
      background: rgba(255, 255, 255, 0.15);
      box-shadow: -0.75px -0.75px 0px rgb(255, 255, 255);
      backdrop-filter: blur(32px);
      padding: 15px;
      width: 340px;
      min-height: 130px;
      bottom: -50px;
      left: 15px;
      z-index: 2;
      display: flex;
      align-items: center;
      justify-content: center;

      h3 {
        color: $white-color;
        font-size: 20px;
        font-weight: 800;
        margin-bottom: 15px;
      }

      .trusted-client-list {
        display: flex;
        align-items: center;
        padding-left: 60px;

        .client-img-tag {
          width: 52px;
          height: 52px;
          border-radius: 50%;
          position: relative;

          img {
            width: 52px;
            height: 52px;
            border-radius: 50%;
          }

          &:nth-child(1) {
          }

          &:nth-child(2) {
            left: -15px;
          }

          &:nth-child(3) {
            left: -30px;
          }

          &:nth-child(4) {
            left: -45px;
          }

          &:nth-child(5) {
            left: -60px;
          }
        }

        .client-total-count-div {
          width: 52px;
          height: 52px;
          border-radius: 50%;
          backdrop-filter: blur(7px);
          background: $theme-color;
          color: var(--1, #fff);
          font-size: 20px;
          font-weight: 800;
          line-height: 120%;
          position: relative;
          left: -75px;
        }
      }
    }
  }

  .automotive-digital-details {
    width: 53%;
    position: absolute;
    right: 0;
    z-index: 9;
    top: -14%;

    h2 {
      color: $white-color;
      font-size: 78px;
      font-family: Oswald, sans-serif;
      font-weight: 600;
      line-height: 120%;
      letter-spacing: -1.56px;
      text-transform: uppercase;
      @include screen("laptop") {
        font-size: 68px;
      }

      .primary-span {
        color: $theme-color;

        .documentation-span {
          padding-left: 28%;
        }
      }

      .in-hand-span {
        padding-left: 20%;
      }
    }

    .automotive-digital-steps {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-top: 10px;
      @include screen("laptop") {
        padding-top: 50px;
      }

      .automotive-steps-card {
        width: 31.5%;
        height: 248px;
        border-radius: 24px;
        background: rgba(34, 37, 45, 0.8);
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 25px;

        img {
          width: 80px;
          height: 80px;
        }

        h6 {
          margin-top: 15px;
          color: $white-color;
          text-align: center;
          font-size: 25px;
          font-family: Oswald, sans-serif;
          font-weight: 500;
          line-height: 140%;
          letter-spacing: -0.25px;
          text-transform: uppercase;
        }

        &.automotive-steps-card-primary {
          background: $theme-color;
        }
      }
    }
  }
}

.shops-highlights-fluid {
  background: $black-color;
  padding: 60px 0;

  .highlights-slider-content-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    // background: linear-gradient(180deg, rgba(0, 0, 0, 0) 59.57%, #000 100%);
    padding: 30px;
    display: flex;
    align-items: flex-end;
    @include screen("mobile") {
      padding: 10px;
    }
    @include screen("tab") {
      padding: 20px;
    }

    h5 {
      color: #ffffff;
      font-size: 32px;
      font-family: Oswald, sans-serif;
      font-weight: 700;
      margin-bottom: 0;
      line-height: 44.8px;
      @include screen("mobile") {
        font-size: 16px;
        line-height: 1.5;
      }
    }

    .ant-btn {
      color: $theme-color;
      font-size: 16px;
      font-weight: 400;
      line-height: 150%;
      letter-spacing: -0.16px;
      background: transparent;
      padding: 0;
      border: none;
      display: flex;
      align-items: center;
      @include screen("mobile") {
        font-size: 11px;
        line-height: 1.2;
      }

      img {
        margin-left: 10px;
      }
    }
  }

  .slider-bottom-slick {
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;

    &:before {
      content: "";
      position: absolute;
      top: 0;
      z-index: 1;
      left: 0;
      height: 100%;
      width: 129px;
      background: linear-gradient(90deg, #000 0%, rgba(0, 0, 0, 0) 100%);
    }
    &::after {
      content: "";
      position: absolute;
      top: 0;
      z-index: 0;
      right: 0;
      height: 100%;
      width: 129px;
      background: linear-gradient(-90deg, #000 0%, rgba(0, 0, 0, 0) 100%);
    }

    li {
      // height: 122px;
      width: 14.28%;
      @include screen("tab") {
        width: 100%;
      }
      @include screen("mobile") {
        height: 100%;
        width: 120px;
        img {
          height: 100%;
          max-width: 120px;
          @include screen("tab") {
            height: inherit;
          }
        }
      }
      &.active {
        button {
          img {
            border: 3px solid #0093ff !important;
          }
        }
      }

      &.list-inline-item:not(:last-child) {
        margin-right: 0;
      }

      .ant-btn {
        height: auto;
        background: transparent;
        border: none;
        padding: 0;

        img {
          height: 100%;
          width: 100%;
          @include screen("tab") {
            height: inherit;
            width: inherit;
          }
        }
      }
    }

    .arrow-slick-highlight {
      background: transparent;
      padding: 0;
      border: none;
      top: 0;
      bottom: 0;
      z-index: 1;

      &.arrow-right-slick-highlight {
        right: 15px;
      }

      &.arrow-left-slick-highlight {
        left: 15px;
      }
    }
  }
}

.our-community-fluid {
  background: $black-color;
  padding: 0px 0 60px;
  overflow: hidden;

  .slick-dots {
    display: none !important;
  }

  .community-heading {
    margin-bottom: 45px;

    h3 {
      color: $white-color;
      font-size: 62px;
      font-family: Oswald, sans-serif;
      font-weight: 600;
      line-height: 120%;
      letter-spacing: -1.24px;
      text-transform: uppercase;
      @include screen("tab") {
        margin-bottom: 30px;
      }

      span {
        color: $theme-color;
      }
    }
  }

  .arrow-with-count {
    top: -50%;
    right: 15px;
    width: auto;
    @include screen("tab") {
      top: -20% !important;
    }
    @include screen("mobile") {
      top: -8% !important;
    }

    ul {
      display: flex;
      align-items: center;
      @include screen("tab") {
        max-width: 500px;
      }
      li {
        display: flex;
        align-items: center;
        color: #d4d4d4;
        font-size: 20px;
        font-family: Oswald, "sans-serif";
        font-weight: 700;
        text-transform: uppercase;
        &:nth-child(2) {
          min-width: 70px;
        }

        span {
          color: $theme-color;
        }
      }
    }
  }

  .community-slider-data {
    .community-member-img {
      width: 215px;

      img {
        width: 100%;
        object-fit: cover;
        margin-top: 10px;
        max-height: 160px;
      }
    }
    .community-slider-data {
      padding-right: 15px;
    }
    .community-details-div {
      width: calc(100% - 215px);
      padding-left: 15px;
      padding-right: 15px;
      // @include screen("tab") {
      //   width: 100%;
      //   padding-left: 0px;
      //   margin-top: 15px;
      // }

      p {
        color: $grasS;
        font-size: 19px;
        font-weight: 300;
        line-height: 170%;
        opacity: 0.7;
      }

      ul {
        li {
          img {
          }
        }
      }

      h6 {
        color: $white-color;
        font-size: 25px;
        font-family: Oswald, sans-serif;
        font-weight: 500;
        line-height: 140%;
        letter-spacing: -0.25px;
      }
    }
  }

  .ion-app-details-card {
    margin-top: 30px;
    background: url("../images/background-pattren-img-app-download.png")
      no-repeat center;
    background-color: #1a2532;
    background-size: cover;
    padding: 0px 60px;
    border-radius: 36px;
    overflow: hidden;
    // min-height: 400px;

    h4 {
      margin-bottom: 30px;
      color: $white-color;
      font-size: 64px;
      font-family: Oswald, sans-serif;
      font-weight: 700;
      line-height: 115%;
      letter-spacing: -1.92px;
      text-transform: uppercase;

      span {
        color: $theme-color;
      }
    }

    ul {
      li {
        img {
          height: 55px;
        }
      }
    }

    .mockup-ios-div {
      top: -198px;
      z-index: 1;
      left: -70px;
    }

    .mockup-android-div {
      right: 0;
      top: -137px;
      @include screen("mobile") {
        top: 15px;
      }
    }
  }

  .facebook-update-main-row {
    padding: 130px 0 0px;
    overflow-x: hidden;
    @include screen("tab") {
      margin-top: 90px;
      margin-bottom: 20px;
    }
    .fb-update-heading {
      @include screen("tab") {
        margin-top: 0px;
        margin-bottom: 20px;
      }
      h5 {
        color: $white-color;
        font-size: 56px;
        font-family: Oswald, sans-serif;
        font-weight: 600;
        line-height: 120%;
        letter-spacing: -1.12px;
        text-transform: uppercase;
        margin-bottom: 30px;

        span {
          color: $theme-color;
        }
      }

      .connect-fb-btn {
        height: 60px;
        width: 100%;
        max-width: 320px;
        background: $theme-color;
        border-radius: 50px;
        color: $white-color;
        font-size: 20px;
        font-weight: 400;
        border: none;
        @include screen("tab") {
          font-size: 18px;
        }

        img {
          margin-right: 5px;
        }
      }
    }

    .fb-slider-carousel {
      .slick-list {
        min-height: 100%;
        width: calc(100% + 300px);
        @include screen("mobile") {
          min-height: 300px;
          height: 100% !important;
          width: 100%;
        }
        .slick-track {
          .slick-slide {
            padding-right: 15px;
          }
        }
      }
    }
    .fb-slider-row {
      .fb-slide-inner {
        height: 100%;
        @include screen("tab") {
          height: auto;
        }
        &::after {
          content: "";
          width: 100%;
          position: absolute;
          bottom: 0;
          left: 0;
          height: 20%;
          background: linear-gradient(
            0deg,
            rgba(0, 0, 0, 0.21) 0%,
            rgba(0, 0, 0, 0) 100%
          );
          z-index: 0;
          @include screen("mobile") {
            height: 40%;
            background: linear-gradient(
              0deg,
              rgba(0, 0, 0, 0.95) 0%,
              rgba(0, 0, 0, 0) 100%
            );
            z-index: 0;
          }
        }
        img {
          height: 390px;
          object-fit: cover;
          width: 100%;
          @include screen("tab") {
            height: auto;
          }
        }
        div {
          .highlights-slider-content-overlay {
            bottom: 15px;
            left: 15px;
            z-index: 2;

            h5 {
              color: $white-color;
              font-size: 24px;
              font-family: Oswald, sans-serif;
              font-weight: 400;
              line-height: 180%;
              letter-spacing: -0.24px;
              text-transform: uppercase;
              margin-bottom: 0;
            }
          }
        }
      }
    }

    .arrow-with-count {
      top: auto;
      bottom: -25%;
      margin-bottom: 0;
    }
  }
}
// new css

.carousel-img-track {
  width: 100%;
  overflow-x: auto;
  img.img-fluid {
    width: 25%;
  }
  &::after {
    content: "";
    height: 100%;
    position: absolute;
    width: 60px;
    background: linear-gradient(270deg, #000 0%, rgba(0, 0, 0, 0) 100%);
    right: 0;
  }
}
.carousel-img-container {
  display: flex;
  position: relative;
  .arrow-right-slick {
    right: -20px;
    top: calc(50% - 19px);
    background: none;
    border: none;
    @include screen("tab") {
      right: -10px;
    }
  }
}

.accord-body {
  background: #0093ff;
  overflow: hidden;
}

img.img-fluid.shopHighlightImg {
  width: 100%;
}
