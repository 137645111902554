@import "../../assets/styles/ion-styles/media-queries";
.navbar_container {
  position: sticky;
  top: 0;
  background-color: blue;
  height: 100px;
}
.editDetails_container {
  display: flex;
  flex-direction: column;
  background-color: black;
  width: 100%;
  min-height: 100vh;
  overflow-y: auto; /* Enable vertical scrolling */
}

.icon-container {
  display: flex;
  flex-direction: column;
  /* background-color: red; */
  align-items: center;
  justify-content: center;
  padding-top: 40px; /* Adjust the top padding to accommodate the fixed navigation bar height */
}

.icon-inner-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 5px;
}

.logo-title {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Manrope;
  font-size: 55px;
  font-weight: 800;
  text-align: center;
  color: white;
  line-height: 1.5;
  margin: 1rem auto 1rem auto;
}
.details_container {
  display: flex;
  /* background-color: blueviolet; */
  align-items: center;
  width: 100%;
  max-width: 500px;
  padding: 7px;
  line-height: 2;
}
.left-lable {
  font-weight: 400;
}

.right-value {
  color: white;
  font-weight: 400;
  font-size: 20px !important;
  &:focus {
    color: #0093ff;
    caret-color: #aefd09;
  }
}
.edit-button-conatiner {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
}

.edit-button {
  display: flex;
  border: none;
  outline: none;
  background-color: #0093ff;
  padding: 8px 16px 8px 16px;
  width: calc(100% - 30px);
  max-width: 432px;
  margin-left: 30px;
  color: white;
  gap: 10px;
  border-radius: 10px;
  font-size: 20px;
  height: 65px;
  align-items: center;
  justify-content: center;
  &:hover {
    color: #fff !important;
  }
  @include screen("mobile") {
    margin: 0 auto;
    width: 90%;
  }
}

.right-value.react-tel-input:disabled {
  cursor: default;
}

input.ant-input.ant-input-borderless.right-value {
  color: #fff;
  // font-weight: 400;
  @include screen("mobile") {
    padding: 0px;
  }
  &.ant-input-disabled {
    color: #fff;
    cursor: default !important;
  }
  &:focus {
    color: #0093ff;
    caret-color: #aefd09;
  }
}
textarea.ant-input.ant-input-borderless {
  color: #fff;
  @include screen("mobile") {
    padding: 0px;
  }
  &.ant-input-disabled {
    color: #fff;
  }
  &:focus {
    color: #0093ff;
    caret-color: #aefd09;
  }
}

.upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
}
.upload-btn-wrapper input[type="file"] {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  height: 140px;
  width: 140px;
  cursor: pointer;
}
.profile-form {
  .ant-form-item {
    margin-bottom: 0.5rem;
    .ant-row .ant-form-item-label {
      text-align: left;
      color: #d4d4d4;
      opacity: 0.7;
      font-weight: 400;
    }
  }
}

img.profile-file-upload-handle {
  border-radius: 50%;
  object-fit: cover;
  border: 3px solid #0093ff;
}
img.edit-image-icon {
  position: absolute;
  right: 0;
  bottom: 0;
}

.ant-form-item .ant-form-item-label > label::after {
  display: none;
}

.edit-detail-label {
  color: rgb(212, 212, 212);
  font-weight: 300;
  text-align: left;
  margin-bottom: 0px;
  font-size: 20px;
  margin-bottom: 20px;
}
.edit-details-form-item {
  label {
    height: unset !important;
  }
}
