@import "theme.scss";

.common-form {
  .ant-form {
    .ant-form-item {
      margin-bottom: 32px;

      &.zip-card-item {
        padding-top: 20px;

        .ant-form-item-row {
          display: flex;
          align-items: center;
        }
      }
      .ant-form-item-label {
        padding-bottom: 2px;
        text-align: left;

        label {
          font-weight: 600;
          font-family: "Manrope";
          font-size: 21px;
          padding-bottom: 5px;
          color: $white-color;
          padding-left: 10px;
          &:after {
            display: none;
          }
        }
      }

      .ant-form-item-control {
        .ant-form-item-control-input {
          .ant-form-item-control-input-content {
            .ant-input {
              background: $gray-rgba-6;
              height: 72px;
              border-radius: 14px;
              border: 1.5px solid $gray-rgba-6;
              color: $white-color;
              font-weight: 500;
              font-size: 18px;
              padding: 10px 15px;
              font-family: "Manrope";

              &::-webkit-input-placeholder {
                color: $grasS;
              }

              &:focus {
                background: rgba(76, 123, 157, 0.25);
                border: 1px solid $theme-color;
              }

              &.textArea-address {
                height: 102px !important;

                &.textArea-contact {
                  height: 151px !important;
                }
              }
            }

            .ant-select {
              .ant-select-selector {
                background: $gray-rgba-6;
                height: 72px;
                border-radius: 14px;
                border: 1.5px solid $gray-rgba-6;
                color: $white-color;
                font-weight: 500;
                font-size: 18px;
                padding: 10px 15px;

                .ant-select-selection-search {
                  .ant-select-selection-search-input {
                    height: 70px;
                  }
                }

                .ant-select-selection-placeholder {
                  line-height: 2.5;
                  color: rgb(212 212 212 / 70%);
                  font-weight: 300 !important;
                  font-size: 18px;
                }
              }
            }

            .ant-checkbox-wrapper {
              font-weight: 400;
              font-size: 19px;
              color: $grasS;

              a {
                text-decoration-line: underline;
                color: $theme-color;
                font-weight: 700;
              }

              .ant-checkbox {
                .ant-checkbox-inner {
                  background: transparent;
                  border-color: $white-color;
                  width: 18px;
                  height: 18px;
                  border-radius: 5px;
                }
              }

              &.ant-checkbox-wrapper-checked {
                .ant-checkbox {
                  .ant-checkbox-inner {
                    background: $theme-color;
                    border-color: $theme-color;

                    &:after {
                      left: 4px;
                      top: 44%;
                    }
                  }
                }
              }
            }

            .ant-input-password {
              background: $gray-rgba-6;
              height: 72px;
              border-radius: 14px;
              border: 1.5px solid $gray-rgba-6;
              color: $white-color;
              font-weight: 500;
              font-size: 18px;
              padding: 10px 15px;

              &:focus {
                background: rgba(76, 123, 157, 0.25);
                border: 1px solid $theme-color;
              }

              .ant-input {
                background: transparent;
                border: none;
                padding: 0;
                height: auto;
              }

              .ant-input-suffix {
                svg {
                  color: $white-gray-rgba;
                }
              }
            }
          }
        }
      }

      .auth-forgot-pwd {
        font-weight: 300;
        font-size: 16px;
        color: $white-color;
      }

      &.ant-form-item-has-success {
        .ant-form-item-control {
          .ant-form-item-control-input {
            .ant-form-item-control-input-content {
              .ant-input {
                background: rgba(76, 123, 157, 0.25);
                border: 1px solid $theme-color;
              }

              .ant-input-password {
                background: rgba(76, 123, 157, 0.25);
                border: 1px solid $theme-color;

                .ant-input {
                  background: transparent;
                  border: none;
                  padding: 0;
                  height: auto;
                }
              }
            }
          }
        }
      }
    }

    .small-text-info {
      font-family: "Manrope";
      position: relative;
      bottom: 15px;
      font-weight: 600;
      font-size: 18px;
      color: $white-color;

      span {
        color: $theme-secondary;
      }
    }
  }

  &.common-form-forgot-pwd {
    .ant-form {
      .ant-form-item {
        margin-bottom: 45px;

        .ant-form-item-control {
          .ant-form-item-control-input {
            .ant-form-item-control-input-content {
              .ant-input-affix-wrapper {
                &:focus {
                  background: rgba(76, 123, 157, 0.25);
                  border: 1px solid $theme-color;
                }
              }
            }
          }
        }
      }
    }
  }

  .multiple-check-div {
    margin: 30px 0 45px;

    .ant-checkbox-group {
      ul {
        text-align: center;

        li {
          margin-top: 15px;

          .ant-checkbox-wrapper {
            height: 50px;
            background: #51545e82;
            border-radius: 36px;
            padding: 12px 23px;
            color: $white-color;
            font-size: 16px;
            position: relative;
            overflow: hidden;
            display: flex;
            align-items: center;

            img {
              position: absolute;
              right: -100%;
              top: 0;
              bottom: 0;
              margin: auto;
            }

            .ant-checkbox {
              display: none;
            }

            &:hover {
              background: $theme-secondary;
              color: $black-color;
            }

            &.ant-checkbox-wrapper-checked {
              background: $theme-color;
              color: $white-color;
              padding: 12px 34px 12px 12px;

              img {
                right: 12px;
              }
            }
          }
        }
      }
    }
  }

  .upload-img-tag {
    margin-bottom: 45px;

    .ant-upload-wrapper {
      .ant-upload {
        .ant-btn {
          display: flex;
          align-items: center;
          justify-content: center;
          border: 1px solid $white-color;
          height: 55px;
          padding: 16px 35px;
          background: transparent;
          border-radius: 4px;
          color: $white-color;
          font-size: 18px;
        }
      }
    }
  }

  .images-upload-list {
    ul {
      overflow-x: auto;
      max-width: 350px;
      li {
        &.list-inline-item:not(:last-child) {
          margin-right: 0.6rem;
        }

        .images-section {
          width: 68px;
          height: 68px;
          border-radius: 16px;
          cursor: pointer;
          transition: 0.3s ease all;

          img {
            width: 68px;
            height: 68px;
            border-radius: 16px;
            object-fit: cover;
          }
        }
      }
    }
  }
}

.contact-us-fluid {
  .common-form {
    .ant-form {
      .ant-form-item {
        .ant-form-item-label {
          label {
            font-size: 20px;
          }
        }
      }
    }
  }
}

.react-tel-input .flag-dropdown.open .selected-flag {
  background: none !important;
  border-radius: 3px 0 0 0;
  width: 50px !important;
}

.react-tel-input .selected-flag:hover,
.react-tel-input .selected-flag:focus {
  background-color: transparent !important;
  width: 50px !important;
}

.react-tel-input .country-list {
  background-color: black !important;
}
.react-tel-input .country-list .country:hover {
  background: #0093ff !important;
}
.react-tel-input .country-list .country.highlight {
  background: #0093ff !important;
}

.react-tel-input .country-list .country .dial-code {
  color: #ffffff !important;
}

.react-tel-input .flag-dropdown.open .selected-flag {
  background: none !important;
  border-radius: 3px 0 0 0;
  width: 50px;
}
.react-tel-input .selected-flag:hover,
.react-tel-input .selected-flag:focus {
  background-color: transparent;
}
.react-tel-input .selected-flag {
  width: 50px;
}

input.inside-input {
  background: none !important;
  border: none !important;
}

span.ant-input-affix-wrapper {
  background: rgba(81, 84, 94, 0.51);
  height: 72px;
  border-radius: 14px;
  border: 1.5px solid rgba(81, 84, 94, 0.51);
}

.inside-input {
  span.ant-input-prefix {
    color: #afff06;
    font-weight: 300;
    font-size: 18px;
    line-height: 25px;
    &:after {
      content: "/";
      color: #ffffff6e;
      margin: 0px 15px;
      @include screen("mobile") {
        margin: 0px 15px;
      }
    }
  }
  input {
    background: none !important;
    border: none !important;
    height: 100% !important;
  }
}

ul.social-media-row {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  // padding: 10px 0 20px;

  li {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;

    p {
      margin-top: 15px;
      font-weight: 300;
      text-overflow: ellipsis;
      max-width: 120px;
      height: 20px;
      overflow: clip;
    }

    .ant-btn.social-media-btn {
      background: $gray-rgba-6;
      height: 62px;
      width: 62px;
      border-radius: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0;
      border: none;

      &:hover,
      &.active {
        &.instagramUrl {
          background: linear-gradient(
            45deg,
            #405de6,
            #5851db,
            #833ab4,
            #c13584,
            #e1306c,
            #fd1d1d
          );
          & + p {
            color: #833ab4;
            font-weight: 300;
          }
        }
        &.facebookUrl {
          background: $theme-color;
          & + p {
            color: $theme-color;
            font-weight: 300;
          }
        }
        &.youtubeUrl {
          background: #cd201f;
          & + p {
            color: #cd201f;
            font-weight: 300;
          }
        }
        &.appleLink {
          background: #979797;
          & + p {
            color: #979797;
            font-weight: 300;
          }
        }
        &.snapchatLink {
          background: #fffc00;
          & + p {
            color: #fffc00;
            font-weight: 300;
          }
          img {
            background-color: #000;
            border-radius: 50%;
          }
        }
      }
    }

    .handler-name-tag {
      font-weight: 700;
      font-size: 14px;
      line-height: 19px;
      text-align: center;
      color: $theme-color;
      position: absolute;
      bottom: -25px;
      left: 0;
    }
  }
}

.active-social-link-div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: relative;
  top: -15px;
  padding: 8px 8px 8px 15px;
  width: 100%;
  height: 72px;
  background: $gray-rgba-7;
  border: 1px solid $theme-color;
  border-radius: 14px;
  margin-bottom: 15px;

  .social-medial-detail-div {
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
    color: #ffffff;
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .active-social-link {
      color: $theme-secondary;
    }

    .divider {
      color: $white-gray-rgba;
    }
  }

  .social-status-div {
    img {
      height: 54px;
    }
  }
}
.ant-input-data-count {
  display: none;
}

.Intl-phone-Input {
  &.ant-input-group-wrapper-status-success {
    background: rgba(76, 123, 157, 0.25);
    border: 1px solid #0093ff;
    border-radius: 14px;
    .ant-input-group-addon {
      background: transparent !important;
      .ant-select.tel-add-on {
        background-color: transparent !important;
        .ant-select-selector {
          background: transparent !important;
          border-right: none !important;
        }
      }
    }
  }
  .ant-input-group-addon {
    border: none !important;
  }
  .ant-input-wrapper {
    .ant-input {
      &:focus {
        background: rgba(81, 84, 94, 0.46) !important;
        border: none !important;
        box-shadow: none !important;
      }
    }
  }
  .ant-select.tel-add-on {
    background: none;
    .ant-select-selector {
      background: rgba(81, 84, 94, 0.46) !important;
      border-radius: 14px 0px 0px 14px !important;
      border-right: none !important;
      &:after {
        width: 1px !important;
        border-right: 1px solid rgba(225, 225, 231, 0.75);
        position: relative;
        background: rgba(225, 225, 231, 0.75);
        right: -15px;
        height: 50%;
        visibility: visible !important;
        top: 25%;
        opacity: 50%;
      }
    }
  }
  .ant-input-group {
    .ant-input {
      border-radius: 0px 14px 14px 0px !important;

      &.ant-input-status-success {
        background: none !important;
        border: none !important;
        border-left: none !important;
        box-shadow: none;
        outline: none;
      }
      border-left: none !important;
    }
  }

  &:focus-within {
    background: rgba(76, 123, 157, 0.25);
    border: 1px solid #0093ff;
    border-radius: 14px;
    .ant-input-wrapper .ant-input-group {
      .ant-input-group-addon {
        .ant-select.tel-add-on {
          background: transparent !important;
          .ant-select-selector {
            background: transparent !important;
          }
        }
      }

      .ant-input-group {
        .ant-input {
          border-radius: 0px 14px 14px 0px !important;

          &.ant-input-status-success {
            background: none !important;
            border: none !important;
            border-left: none !important;
            box-shadow: none;
            outline: none;
          }
          border-left: none !important;
        }
      }
      .ant-input-group {
        .ant-input {
          border-radius: 0px 14px 14px 0px !important;

          &.ant-input-status-success {
            background: none !important;
            border: none !important;
            border-left: none !important;
            box-shadow: none;
            outline: none;
          }
          border-left: none !important;
        }
      }
    }
  }
}

.ant-select-item.ant-select-item-option {
  padding: 20px;
  background: #51545e !important;
  border-radius: 0px;
  font-size: 20px;
  font-family: "Manrope";
  &:not(:last-child) {
    border-bottom: 1px solid #878e9e;
  }
}

.ant-select-dropdown {
  background: #51545e !important;
  min-width: 150px !important;
}

.ant-select.ant-select-in-form-item.ant-select-status-success {
  background: rgb(19 34 46);
  border: 1px solid #0093ff;
  border-radius: 14px;
  .ant-select-selector {
    background: rgb(19, 34, 46) !important;
  }
}

input[type="color"]::-moz-color-swatch {
  border: none;
  border-radius: 14px;
}

input[type="color"]::-webkit-color-swatch-wrapper {
  padding: 0;
  border-radius: 0;
  border-radius: 14px;
}

input[type="color"]::-webkit-color-swatch {
  border: none;
  border-radius: 14px;
}
p.color-picker-text {
  position: absolute;
  top: 22px;
  left: 25px;
  // filter: invert(1);
  font-size: 1.2rem;
}

.common-form
  .ant-form
  .ant-form-item.color-picker
  .ant-form-item-control
  .ant-form-item-control-input {
  .ant-form-item-control-input-content {
    background: rgb(44 45 52);
    border-radius: 14px;
    .ant-input {
      background: none;
      width: 50px;
      height: calc(72px - 20px);
      margin: 10px;
      padding: 0px;
      float: right;
    }
  }
}

.common-form
  .ant-form
  .ant-form-item.color-picker.ant-form-item-has-success
  .ant-form-item-control
  .ant-form-item-control-input {
  .ant-form-item-control-input-content {
    background: rgba(76, 123, 157, 0.25);
    border: 1px solid #0093ff;
    .ant-input {
      border: none;
    }
  }
}

.Intl-phone-Input.right-value {
  &.ant-input-group-wrapper-status-success {
    background: none;
    border: none;
    border-radius: none;
  }
  &:focus-within {
    background: none;
    border: none;
    border-radius: none;
  }
  &.ant-input-group-wrapper-disabled {
    cursor: default;
    .ant-input-group-addon {
      .ant-select {
        .ant-select-selector {
          color: #fff;
          cursor: default;
        }
      }
    }
    .ant-input {
      color: #fff;
      cursor: default;
    }
  }
  .ant-input-group-addon {
    border: none !important;
    background: none !important;
    .ant-select {
      .ant-select-selector {
        border: none !important;
        background: none !important;
        box-shadow: none !important;
        font-size: 20px !important;
      }
      &:hover {
        border: none !important;
        background: none !important;
        .ant-select-selector {
          border: none !important;
          background: none !important;
        }
      }
    }
  }
  .ant-input {
    font-size: 20px !important;
    color: #fff;
    &:focus {
      border: none !important;
      background: none !important;
      color: #0093ff;
      caret-color: #aefd09;
    }
    border: none !important;
    background: none !important;
  }
}
