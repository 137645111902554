@import "theme";
.enduseragreement-content {
  padding: 0 5.2rem;
  margin-top: 6rem;
}
.terms-main-header h2 {
  font-weight: 700;
  font-size: 2rem !important;
  display: flex;
  justify-content: center;
}
.terms-list .ant-list-item {
  padding: 0;
  border-bottom: 0;
  margin-left: 15px;
}
.terms-list .ant-list-item .ant-list-item-meta .ant-list-item-meta-title {
  font-family: "Manrope", "Oswald", sans-serif;
  font-size: 16px;
}
.terms-list .ant-list-item .ant-list-item-meta .ant-list-item-meta-avatar {
  margin-inline-end: 4px;
  font-size: 16px;
  top: -0.2rem;
  position: relative;
}
.terms-main-header {
  padding: 60px;
  padding-bottom: 20px;
  @include screen("mobile") {
    padding: 0px;
  }
}
.terms-main-header h5 {
  display: flex;
  justify-content: center;
  padding: 2rem 0;
}
.terms-sub-child-header {
  margin: 0 2rem;
  font-size: 16px !important;
}
.terms-sub-header {
  margin: 0 1rem;
  font-size: 16px !important;
}
.scroll:hover {
  background-color: #fcb900;
  border-radius: 200px;
}
.scroll {
  position: fixed;
  z-index: 100;
  border-radius: 20px;
}
.terms-text span {
  margin-right: 0.2rem;
  font-size: 16px;
}
.terms-text p {
  margin-right: 0.2rem;
  font-size: 16px;
}
.terms-text .desc {
  font-weight: 800;
}
.terms-header {
  margin-top: 0 !important;
  font-size: 16px !important;
}
.sub-header {
  padding-left: 15px;
  font-size: 16px !important;
}
