.popup.ant-modal.ant-modal-content {
  background-color: #212125;
  width: 482px !important;
  height: auto !important;
  padding: 8px !important;
  // box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2) !important;
  border-radius: 4px !important;
  text-align: center !important;
  font-family: "Manrope";
}
.error-text {
  color: #9e2146;
}

.popup-title {
  font-size: 25px !important;
  margin-bottom: 10px !important;
  color: white !important;
  font-size: 1.3rem;
  line-height: 1.3;
  width: 70%;
  margin: auto;
  font-weight: 600;
  padding: 5px;
  font-family: "Manrope";
}

.pop-desc {
  margin-bottom: 20px;
  padding: 5px;
  font-size: 16px;
  font-weight: 400;
  text-align: center;
  color: #909091;
  font-family: "Manrope";
}

.button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
  cursor: pointer;
}
.button-container-card {
  display: flex;
  align-items: center;
  gap: 15px;
  cursor: pointer;
}
.cancel-button-card {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px 20px;
  border: none;
  cursor: pointer;
  width: 250px;
  background: #393b42;
  color: #909091;
  border-radius: 10px;
}

.primary-button-card {
  justify-content: center;
  cursor: pointer;
  height: 62px;
  border-radius: 14px;
  background: dodgerblue;
  border: none;
  color: white;
  display: grid;
  align-items: center;
  padding-left: 24px;
  width: 100%;
  font-size: 20px;
  outline: none;
}

/* Style for the disabled state */
.primary-button-card:disabled {
  background-color: gray; /* Change the background color */
  cursor: not-allowed; /* Change the cursor to not-allowed */
  /* You can add more styles as needed */
}

.cancel-button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  width: 200px;
  background: #393b42;
  color: #909091;
  border-radius: 10px;
}

.subscription-button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  width: 200px;
  background: rgba(255, 94, 94, 1);
  color: #fff;
  border-radius: 10px;
}
.primary-button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  width: 200px;
  background-color: dodgerblue;
  color: #fff;
  border-radius: 10px;
  &:disabled {
    opacity: 0.3;
    cursor: not-allowed;
  }
}

.ant-modal.success-popup {
  width: 100vw !important;
  height: 100vh !important;
  max-width: 100vw !important;
  .ant-modal-content {
    height: 100vh !important;
    border-radius: 0px !important;
    background: url("../../../public/assets/images/Create\ Profile.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-color: #000;

    .ant-modal-body {
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      p.title {
        font-family: "Manrope";
        font-size: 28px;
        font-weight: 700;
        margin-top: 1rem;
      }
    }

    .ant-modal-close-x {
      display: none;
    }
  }
}
