@import "theme";
.enduseragreement-content {
  padding: 0 5.2rem;
  margin-top: 6rem;
}
.enduser-header {
  display: flex;
  justify-content: center;
  padding: 60px;
  @include screen("mobile") {
    padding: 0px;
  }
}
.enduser-header h2 {
  font-weight: 700;
  font-size: 2rem;
}
.enduser-title {
  font-size: 16px !important;
}
.enduserList .ant-list-item {
  padding: 0;
  border-bottom: 0;
  font-size: 16px;
}
.enduserList .ant-list-item .ant-list-item-meta .ant-list-item-meta-title {
  // font-family: "Manrope", "Oswald", sans-serif;
  font-size: 16px;
}
.enduser-title span {
  font-weight: 800;
}
.scroll:hover {
  background-color: #fcb900;
  border-radius: 200px;
}
.scroll {
  position: fixed;
  z-index: 100;
  border-radius: 20px;
}
