@import "../../assets/styles/theme.scss";

.categories-input-container {
  position: relative;

  .upload-image-icon {
    position: absolute;
    cursor: pointer;
    right: 25px;
    top: 23px;
  }
}

.categories-custom-input {
  padding: 20px 24px;
  border: none;
  border-radius: 14px;
  font-size: 18px;
  line-height: 0;
  border: 1px solid transparent;
  transition: border-color 0.3s ease;

  :focus {
    border: 1.5px solid $theme-color;
    outline: none;
  }

  &:not(:placeholder-shown) {
    border: 1.5px solid $theme-color;
  }
}

.flex-center {
  display: flex;
  align-items: center;
}

.focused-class {
  border: 1.5px solid $theme-color;
  outline: none;
}

.categories-custom-text-area {
  padding: 8px 24px !important;
}

.categories-save-btn {
  padding: 16px 64px !important;
}

.categories-custom-select {
  .ant-select-selector {
    background-color: #1b1b1b !important;
    color: $white-color !important;
    border: none !important;
    height: 46px !important;
    padding: 10px 24px !important;
    border-radius: 32px !important;
    gap: 8px;
  }

  .ant-select-single {
    border-radius: 32px;
  }

  .ant-select-selection-item {
    font-size: 17px !important;
  }

  .ant-select-arrow {
    right: 30px !important;
    font-size: 16px !important;
    top: 53% !important;
    color: $white-color !important;
  }
}

.categories-custom-select-popup {
  background-color: #313131 !important;
  padding: 5px 16px !important;

  .ant-select-item.ant-select-item-option {
    padding: 8px !important;
    font-size: 17px !important;
    background-color: #313131 !important;
  }
}