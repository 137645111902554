@import "assets/styles/theme.scss";

.input-box {
  height: 62px;
  border-radius: 14px;
  background: rgba(81, 84, 94, 0.51);
  border: none;
  color: white;
  display: grid;
  align-items: center;
  padding-left: 24px;
  width: 100%;
  font-size: 20px;
  outline: none;
}
.input-box:focus {
  border: none;
}
.input-box::placeholder {
  font-size: 18px;
}
.input-box-popup {
  height: 62px;
  border-radius: 14px;
  background: rgba(81, 84, 94, 0.51);
  border: none;
  color: white;
  display: grid;
  align-items: center;
  padding-left: 24px;
  width: 100%;
  font-size: 20px;
  outline: none;
}
.input-box-popup:focus {
  border: none;
}
.input-box-popup::placeholder {
  font-size: 18px;
}
.ElementsApp .input {
  display: none !important;
}

.popup-close-button {
  position: absolute;
  top: 10px;
  right: 20px;
  cursor: pointer;
  background: none;
  border: none;
  outline: none;
  padding: 0;
  font-size: 25px;
  color: #fff;
  font-weight: 800;
}
body {
  padding: 0;
  margin: 0;
  font-size: 14px;
  font-family: "Manrope" "Oswald", sans-serif;
  background: #000 !important;

  a,
  button {
    transition: 0.3s ease all;
    text-decoration: none;

    &:hover {
      text-decoration: none;
    }
  }
}

.App {
  text-align: left;
}

.ant-btn {
  &.theme-common-btn {
    background: $theme-color;
    border-color: $theme-color;
    height: 72px;
    border-radius: 14px;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: $white-color;

    &.subscribe-btn {
      margin-top: 15px;
    }
  }

  &.add-more-btn {
    height: 60px;
    border: 1px solid $grasS;
    border-radius: 14px;
    font-weight: 600;
    font-size: 20px;
    line-height: 27px;
    color: $grasS;
    background: transparent;
    margin-top: 20px;

    &:hover {
      border: 1px solid $grasS;
      color: $grasS;
      background: transparent;
    }
  }

  &.skip-btn {
    color: $theme-secondary !important;
    font-weight: 600;
    font-size: 22px !important;
    text-decoration-line: underline !important;
    position: absolute;
    right: -10%;
    top: 75px;
    width: fit-content;

    &.skip-btn-bottom {
      right: 0;
      color: $white-color;
      top: 0;
      margin-top: 45px;
    }
  }

  &.close-btn-upload-img {
    height: 56px;
    width: 56px;
    background: #51545e82;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    margin-left: 5px;
  }

  &.add-more-btn-upload {
    height: 56px;
    padding: 16px 25px;
    border: 1px solid $white-color;
    border-radius: 75px;
    display: flex;
    align-items: center;
    font-size: 18px;
    color: $white-color;
    font-weight: 500;
    justify-content: center;
    margin-left: 30px;
    background: transparent;

    img {
      margin-right: 5px;
    }

    &:hover {
      border: 1px solid $white-color;
      color: $white-color;
    }
  }

  &.grid-list-view-btn {
    display: flex;
    width: 155px;
    height: 56px;
    padding: 16px 24px;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    background: rgba(227, 243, 255, 0.2);
    color: $white-color;
    font-size: 16px;
    font-weight: 600;

    img {
      width: 24px;
      height: 24px;
      margin-right: 10px;
    }
    &:hover {
      color: $white-color;
      background: $theme-color;
    }
  }

  &.add-restoration-btn {
    display: flex;
    padding: 16px 24px;
    height: 56px;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    background: $theme-color;
    color: $white-color;
    font-size: 18px;
    font-weight: 700;
    img {
      width: 24px;
      height: 24px;
      margin-right: 10px;
    }
  }
}

.ant-btn.add-restoration-btn:disabled,
.ant-btn.add-restoration-btn:disabled {
  opacity: 0.5;
}
.ant-btn.theme-common-btn:disabled,
.ant-btn.add-more-btn:disabled {
  opacity: 0.5;
}

.common-heading {
  margin-bottom: 45px;

  h6 {
    text-align: center;
    color: $white-color;
    font-size: 70px;
    font-family: Oswald, sans-serif;
    font-weight: 600;
    line-height: 120%;
    letter-spacing: -1.4px;
  }
}

.ant-dropdown {
  &.profile-dropdown {
    border-radius: 16px;
    overflow: hidden;
    min-width: 310px !important;
    right: 15px;
    // left: auto !important;
    .ant-dropdown-menu {
      min-height: 244px;
      background: $black-color;
      border-radius: 16px;
      padding: 0;
      .ant-dropdown-menu-item {
        border-bottom: 1px solid rgba(81, 84, 94, 0.81);
        background: $black-color;
        padding: 0 20px;
        border-radius: 0;
        .ant-dropdown-menu-title-content {
          a {
            display: flex;
            align-items: center;
            height: 60px;
            color: $white-color;
            font-size: 18px;
            font-weight: 600;
            line-height: normal;

            img {
              margin-right: 15px;
            }
          }
          .ant-btn.logout-btn {
            background: transparent;
            border-radius: 0;
            display: flex;
            border: none;
            align-items: center;
            width: 100%;
            height: 60px;
            color: #db524e;
            font-size: 18px;
            font-weight: 600;
            line-height: normal;
            padding: 0;
            img {
              margin-right: 15px;
            }
          }
        }
        &:last-child {
          border: none;
        }
      }
    }
  }
}

input.ant-input::placeholder {
  color: rgba(212, 212, 212, 0.7) !important;
  font-weight: 300 !important;
}
textarea.ant-input::placeholder {
  color: rgba(212, 212, 212, 0.7) !important;
  font-weight: 300 !important;
}

.color-gray {
  color: $white-gray-rgba !important;
}

.sub-heading {
  font-weight: 500;
  font-size: 20px;
  font-family: "Manrope";
  line-height: 1.6;
  color: rgb(212, 212, 212);
  opacity: 0.7;
  margin-bottom: 2rem;
}

.slick-slider.slider-nav.slick-initialized::before {
  content: "";
  width: 150px;
  height: 100%;
  background: linear-gradient(92deg, black, transparent, transparent);
  position: absolute;
  left: 0;
  z-index: 1;
}

.slick-slider.slider-nav.slick-initialized::after {
  content: "";
  width: 150px;
  height: 100%;
  background: linear-gradient(270deg, black, transparent, transparent);
  position: absolute;
  right: 0;
  z-index: 1;
  top: 0;
}

img.slick-arrow.slick-next {
  position: absolute;
  right: 10px;
  top: calc(50% - 20px);
  z-index: 9;
}

img.slick-arrow.slick-prev {
  position: absolute;
  left: 10px;
  top: calc(50% - 20px);
  z-index: 9;
}
.slick-slide.slick-active.slick-center.slick-current > div > div {
  border: 3px solid #0093ff;
}

.slick-slider.track-slider {
  .slick-list {
    .slick-track {
      max-height: 150px;
      overflow: hidden;
      @include screen("mobile") {
        max-height: 80px;
      }
      .slick-slide {
        max-width: 250px;
        height: 150px;
        @include screen("mobile") {
          max-height: 80px;
        }
        div {
          max-height: 150px;
          height: 100%;
          div.track-img {
            height: 100%;
            max-height: 150px;
            @include screen("mobile") {
              max-height: 80px;
            }
            img.img-fluid {
              max-height: 150px;
              width: 100%;
              height: 100%;
              object-fit: cover;
              @include screen("mobile") {
                max-height: 80px;
              }
            }
          }
        }
      }
    }
  }
}

.slideshow-cancel-btn {
  position: absolute;
  z-index: 9;
  left: 20px;
  top: 20px;
  cursor: pointer;
}

.pop-up-subscribe-btn {
  background: $theme-color;
  border-color: $theme-color;
  line-height: 24px;
  color: $white-color;
  border-radius: 14px;
  font-weight: 700;
  font-size: 1rem;
  font-family: "Manrope";
  width: 200px;
  border: none;
  padding: 10px 20px;
  height: unset;
  margin-top: 0px !important;
  height: unset !important;
}

.ant-spin.full-screen-center-loader {
  max-height: 100% !important;
  .ant-spin-dot {
    top: 85% !important;
  }
  .ant-spin-text {
    top: 85% !important;
  }
}

.no-car-msg {
  p {
    color: #fff;
    font-family: "Manrope";
    font-size: 30px;
    text-align: center;
    margin: 10% auto;
  }
}

.slick-slider {
  touch-action: auto !important;
}

.view-slide-slider {
  .slick-list {
    .slick-track {
      .slick-slide.slick-active.slick-current {
        div {
          max-width: fit-content !important;
          margin: 0 auto;
        }
      }
    }
  }
}

.car-image-upload-container .image-Upload-btn:disabled {
  color: #424242;
  border: 1px solid #424242;
  cursor: not-allowed;
  span {
    img.img-fluid {
      opacity: 0.2;
    }
  }
}
.car-image-upload-container input[type="file"]:disabled {
  cursor: not-allowed;
}
