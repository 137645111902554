@import "theme";
h2 {
  font-weight: 900;
  font-size: 40px;
}

.enduseragreement-content {
  padding: 0 5.2rem;
}
.privacy-list .ant-list-item h5::before {
  content: "";
  width: 5px;
  display: inline-flex;
  height: 5px;
  border-radius: 50%;
  background: 1px #fff;
  position: relative;
  margin-right: 0.5rem;
  top: -0.2rem;
}

.privacy-list .ant-list-items .ant-list-item::after {
  content: "";
  margin-right: 0.5rem;
}
.privacy-contact-header {
  display: flex;
  justify-content: center;
  padding: 60px;
  padding-bottom: 20px;

  @include screen("mobile") {
    padding: 0px;
  }
}
.privacy-contact-header h2 {
  font-weight: 700;
  font-size: 3rem;
  @include screen("mobile") {
    font-size: 2rem;
  }
 
}
.privacy-list .ant-list-item {
  padding: 0;
  border-bottom: 0;
}
.privacy-list .ant-list-items .ant-list-item h5 {
  font-size: 16px !important;
}
.privacy-content {
  font-size: 16px !important;
}
.scroll:hover {
  background-color: #fcb900;
  border-radius: 200px;
}
.scroll {
  position: fixed;
  z-index: 100;
  border-radius: 20px;
}
