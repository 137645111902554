.credit-card {
    width: 398.148px;
    height: 250px;
    flex-shrink: 0;
    /* Add other styles as needed */
  }
  
  /* Additional styles for card details */
  .card-number {
    width: 311.205px;
    height: 30px;
    flex-shrink: 0;
    color: #FFF;
    font-size: 21px;


}
  
  .card-holder {
    color: #FFF;
    font-family: Rubik;
    font-size: 18.111px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;  }
  
  
  .card-expiration {
    color: #FFF;
    font-family: Rubik;
    font-size: 18.111px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;  }
  