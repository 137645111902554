@import "../../assets/styles/theme.scss";
.Viewvehicle_Container {
  padding: 30px 10px;
  background: #000;
  .viewvehical--body {
    display: flex;
    margin-top: 10px;
    .left--view--grid {
      width: 50%;
      @include screen("mobile") {
        width: 100%;
      }
    }
    .right--img-grid {
      display: grid;
      grid-template-columns: 48% 48%;
      grid-template-rows: 48% 48%;
      gap: 4%;
      @include screen("mobile") {
        margin-top: 40px;
      }
      .image--container {
        position: relative;
        max-width: 300px;
        max-height: 300px;
        cursor: pointer;
        .ant-dropdown-trigger {
          position: absolute;
          right: 2px;
          top: 5px;
          cursor: pointer;
          z-index: 9;
        }
        img {
          //   max-width: 250px;
          //   max-height: 250px;
          object-fit: cover;
          width: 100%;
          height: 100%;
          border-radius: 10px;
          max-width: 300px;
          max-height: 300px;
        }
        .See-all-btn {
          top: 0;
          color: #fff;
          text-align: center;
          font-size: 21px;
          position: absolute;
          width: 100%;
          height: calc(100% + 1px);
          display: flex;
          flex-direction: column;
          justify-content: center;
          background: rgb(0 0 0 / 50%);
          p {
            font-size: 38px;
            font-weight: 600;
            color: var(--3, #afff06);
            margin-bottom: 0;
          }
        }
        .image--label {
          position: absolute;
          bottom: -1px;
          left: 0;
          width: 100%;
          color: #fff;
          padding: 5px;
          text-align: left;
          font-size: 16px;
          font-weight: 600;
          height: 100%;
          width: 100%;
          background: linear-gradient(
            360deg,
            #000000ed,
            transparent,
            #0000001c,
            transparent
          );
          div {
            position: absolute;
            bottom: 20px;
            left: 20px;
          }
        }
      }
      .image--container-all {
        position: relative;
        height: 100%;
        width: 100%;
        padding-bottom: 2%;
        opacity: 0.699999988079071;
        .image--label-all {
          color: #fff;
          padding: 5px;
          font-size: 16px;
          display: flex;
          background-image: url("../../assets//images/SeeAll.png");
          height: 100%;
          border-radius: 8px;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          background-repeat: no-repeat;
          img {
            width: 24%;
            height: 8vh;
          }
        }
      }
    }
    .no-image-container {
      @include screen("mobile") {
        justify-content: center !important;
        margin-top: 40px;
      }
    }
  }
}

.vehicle_content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .vehicle-heading {
    flex-shrink: 0;
    color: #fff;
    font-size: 48px;
    font-family: Oswald;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: uppercase;
    max-width: 85%;
  }

  .tick-icon {
    margin: 10px 10px 10px 10px;
    background-color: #d4d4d4;
    height: 40px;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background: var(--3, #0093ff);
    cursor: pointer;
  }
}

// vehicle text
.vehicle-text {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  .text {
    color: var(--colors-gras-s, #d4d4d4);
    font-size: 18px;
    font-family: "Manrope";
    font-style: normal;
    font-weight: 300;
    line-height: 2;
    max-width: 75%;
    span {
      color: $theme-color;
      font-weight: 500;
      text-decoration: underline;
      cursor: pointer;
    }
  }
  .vehicle-dropdown {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    align-items: center;
    gap: 10px;
    span {
      color: var(--colors-gras-s, #d4d4d4);
      font-family: Manrope;
      font-size: 17px;
      font-style: normal;
      font-weight: 400;
      line-height: 200%;
    }
    select {
      color: #fff;
      font-family: Manrope;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      border: 1px solid black;
      border-radius: 32px;
      background: #1b1b1b;
    }
  }
}

.vehicle_details {
  div {
    display: flex;
    margin-bottom: 10px;
  }
  input {
    color: #fff;
    font-size: 21px;
    font-family: Manrope;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    border: none !important;

    background-color: black;
    color: white;
  }
  label {
    display: flex;
    color: red;
    width: 150px;
    flex-direction: column;
    color: var(--colors-gras-s, #d4d4d4);
    font-size: 21px;
    font-family: Manrope;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    opacity: 0.699999988079071;
  }
}

// grid

.Vehicle_Properties {
  height: auto;
  display: flex;
  flex-direction: column;
  @include screen("mobile") {
    display: block;
  }
  .vehicle_properties_containers {
    display: grid;
    grid-template-columns: 30% 30% 30%;
    grid-template-rows: 48% 48%;
    gap: 3%;
    row-gap: 20px;
    color: white;
    @include screen("mobile") {
      display: block;
    }
    .main_box {
      display: flex;
      @include screen("mobile") {
        margin-top: 15px;
      }
      .image_container {
        padding-right: 10px;
        img {
          margin-top: 15px;
        }
      }
      .content_box {
        display: flex;
        flex-direction: column;
        padding-left: 10px;
        .content_name {
          color: var(--colors-gras-s, #d4d4d4);
          opacity: 0.6;
          font-size: 18px;
          font-weight: 400;
          font-family: "Manrope";
        }
        span {
          font-size: 21px;
          font-weight: 500;
        }
      }
    }
  }
}

// colors

.colors {
  .Interior_Color {
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    .Interior-text {
      display: flex;
      width: 150px;
      flex-direction: column;
      color: #fff;
      font-size: 20px;
      font-family: Manrope;
      font-style: normal;
      font-weight: 800;
      line-height: normal;
    }
    .Interior_Color_box {
      width: 53px;
      height: 53px;
      margin-bottom: 10px;
    }
  }

  .Exterior_text {
    display: flex;
    width: 150px;
    flex-direction: column;
    color: #fff;
    font-size: 20px;
    font-family: Manrope;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
  }

  .Exterior_Color_box {
    width: 53px;
    height: 53px;
  }
}

// vehicle properties

.Vehicle_Buttons {
  display: flex;
  width: 100%;
  align-items: flex-start;
  gap: 28px;
  justify-content: space-around;
  margin-top: 20px;
  @include screen("mobile") {
    align-items: stretch;
    flex-direction: column;
    gap: 15px;
  }
  .btn-edit {
    display: flex;
    height: 72px;
    color: white !important;
    padding: 8px 16px;
    justify-content: center;
    align-items: center;
    gap: 12px;
    flex: 1 0 0;
    border-radius: 10px;
    border: 1px solid #000;
    background: var(--3, #0093ff) !important;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  }
  .btn-view {
    display: flex;
    height: 72px;
    color: var(--3, #afff06) !important;
    border: 1px solid var(--6, rgba(81, 84, 94, 0.51)) !important;
    padding: 8px 16px;
    justify-content: center;
    align-items: center;
    gap: 12px;
    flex: 1 0 0;
    border-radius: 10px;
    background: var(--6, rgba(81, 84, 94, 0.51)) !important;
  }

  .btn-dlt {
    display: flex;
    height: 72px;
    padding: 8px 16px;
    justify-content: center;
    align-items: center;
    gap: 12px;
    color: tomato !important;
    flex: 1 0 0;
    border-radius: 10px;
    border: 2px solid #ff6363 !important;
  }
}

.footer {
  h4 {
    color: #fff;
    font-size: 28px;
    font-family: Manrope;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  p {
    color: var(--colors-gras-s, #d4d4d4);
    font-size: 19px;
    font-family: Manrope;
    font-style: normal;
    font-weight: 500;
    line-height: 200%;
    opacity: 0.699999988079071;
  }
}

.ant-select.visiblity-select {
  .ant-select-selector {
    height: 50px;
    width: 130px;
    background: rgba(27, 27, 27, 1);
    color: #fff !important;
    border-radius: 30px;
    border: none;
    .ant-select-selection-search {
      background: rgba(27, 27, 27, 1);
      color: #fff !important;
      border-radius: 30px;
      border: none;
      .ant-select-selection-item {
        font-family: "manrope";
        font-size: 17px;
        font-weight: 600;
        line-height: 26px;
        letter-spacing: 0em;
        text-align: left;
        justify-content: space-evenly;
      }
    }
  }
}

.no-image-container > div {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  text-align: center;
  border: 1px dashed #ccccccbf;
  height: 250px;
  padding: 30px;
  width: 250px;
  h4 {
    color: #fff;
    opacity: 0.7;
    margin-bottom: 30px;
  }
}

.ant-modal.image-modal {
  width: fit-content !important;
  background: #0000 !important;
  .ant-modal-content {
    background: none;
  }
}
