.restoration-container {
  background-image: url("/assets/images/restoration-success-bg-1.png"),
    url("/assets/images/restoration-success-bg-2.png");
  height: 100vh;
  width: 100vw;
  background-repeat: no-repeat, no-repeat;
  background-position: center, unset;
  background-size: cover, auto;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 30px;
}

.restoration-message {
  display: flex;
  flex-direction: column;
  gap: 12px;
}