$breakpoint-mobile: 767px;
$breakpoint-tab: 1080px;
$breakpoint-laptop: 1367px;
$breakpoint-desktop: 1440px;
$breakpoint-large-screen: 1920px;

@mixin screen($size, $type: "max", $pixels: $breakpoint-mobile) {
  @if $size == "mobile" {
    @media screen and ($type + -width: $breakpoint-mobile) {
      @content;
    }
  } @else if $size == "tab" {
    @media screen and ($type + -width: $breakpoint-tab) {
      @content;
    }
  } @else if $size == "laptop" {
    @media screen and ($type + -width: $breakpoint-laptop) {
      @content;
    }
  } @else if $size == "desktop" {
    @media screen and ($type + -width: $breakpoint-desktop) {
      @content;
    }
  } @else if $size == "large" {
    @media screen and ($type + -width: $breakpoint-large-screen) {
      @content;
    }
  } @else if $size == "custom" {
    @media screen and (min-width: $pixels + px) {
      @content;
    }
  } @else {
    @content;
  }
}
