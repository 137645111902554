
@import "theme";
.social-media-link{
    a {
        color: $white-color;
        font-size: 20px;
        font-weight: 400;

        &:hover {
          color: $theme-color;
        }
      }

      .youtube {
        
          &:hover {
            color: #c4302b !important;
          }
        
      }

      .instagram {
        
          &:hover {
            background: -webkit-linear-gradient(
              #be54ff,
              #ff5da1,
              #ff735e,
              #ffd954
            );
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }
        
      }

      .linkedin {
        
          &:hover {
            color: #0a66c2 !important;
          }
        
      }
      .facebook{
        &:hover{
            color: #0093ff !important;
        }
        
      }
}