@import "theme";

.footer-main-fluid {
  background: #15161c;
  padding: 0;
  .footer-row-1 {
    padding: 30px 0;
    align-items: center;
    border-bottom: 1px solid rgba(250, 250, 250, 0.1);
    ul {
      li {
        &.list-inline-item:not(:last-child) {
          margin-right: 1rem;
        }
        a {
          color: $white-color;
          font-size: 18px;
          font-weight: 600;
          &:hover {
            color: #0093ff !important;
          }
        }
       
      }
    }
  }

  .footer-row-2 {
    padding: 45px 0;
    align-items: center;
    h5 {
      color: $white-color;
      font-size: 36px;
      font-family: Oswald, sans-serif;
      font-weight: 500;
      line-height: 125%;
      letter-spacing: -0.72px;
      margin-bottom: 0;
    }
  }

  .footer-row-3 {
    background: $theme-color;
    padding: 30px 0;

    p {
      color: #111111;
      font-size: 18px;
      font-weight: 600;
    }

    ul {
      @include screen("mobile") {
        padding-left: 0px;
      }
      li {
        &.list-inline-item:not(:last-child) {
          margin-right: 32px;
          @include screen("tab") {
            margin-right: 10px;
          }
        }
        a {
          color: #111111;
          font-size: 18px;
          font-weight: 600;
          padding: 0 5px;
          display: inline-block;
        }
      }
    }
  }
}
.footer-logo {
  margin-left: -15px;
  @include screen("mobile") {
    margin-left: 0px;
  }
}
.app-stores-ss {
  @include screen("mobile") {
    margin-bottom: 0px;
  }
}

.footer-main-fluid .footer-row-1 ul li.list-inline-item:not(:last-child) {
  margin-right: 2rem;
}
@include screen("tab") {
  .footer-main-fluid .footer-row-1 ul li.list-inline-item {
    margin-right: 0.9rem;
    &:not(:last-child) {
      margin-right: 0.9rem;
    }
  }
}
@include screen("mobile") {
  .footer-main-fluid .footer-row-1 ul li.list-inline-item {
    margin-right: 0.5rem;
    &:not(:last-child) {
      margin-right: 0.5rem;
    }
  }
}
