@import "theme";

.auths-main-fluid {
  .fix-height {
    min-height: calc(100vh + 300px);
    object-fit: cover;
  }
  .auth-shadow-effect {
    top: 0;
    left: -15px;
    z-index: -13;
    position: fixed;
  }

  .auth-left-div {
    padding: 30px 60px 0;
    position: relative;

    .auth-logo-section {
      z-index: 9;
    }

    .basic-details-auth {
      h4 {
        font-family: "Manrope";
        font-weight: 800;
        font-size: 42px;
        color: $white-color;
        line-height: 1.5;
        margin-bottom: 0.75rem;
        white-space: pre;
        @include screen("mobile") {
          white-space: break-spaces;
        }
      }

      p {
        font-weight: 500;
        font-size: 20px;
        font-family: "Manrope";
        line-height: 1.6;
        color: rgba(212, 212, 212, 1);
        opacity: 0.7;
        margin-bottom: 2rem;
      }
    }

    .auth-footer-details {
      margin: auto;
      position: absolute;
      bottom: 80px;
      width: 100%;
      @include screen("laptop") {
        position: unset;
        margin-top: 20px;
      }
      p {
        font-weight: 400;
        font-size: 21px;
        text-align: center;
        color: $grasS;
        font-weight: 300 !important;

        a {
          color: $theme-secondary;
          font-weight: 600;
          text-decoration: underline;
        }
      }
    }
  }

  .auth-right-div {
    padding: 0;
    height: 100%;

    img {
      height: 100%;
      width: 100%;
    }

    .auth-carousel-effect {
      background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0) 64.41%,
        #000000 100%
      );
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      display: flex;
      align-items: flex-end;
      padding: 15px 30px 30px;

      p {
        font-weight: 400;
        font-size: 25px;
        line-height: 150%;
        letter-spacing: -0.5px;
        color: $white-color;

        span {
          color: $theme-color;
        }
      }
    }

    .slick-dots {
      justify-content: flex-start;
      left: 30px;
      bottom: 15px;

      li {
        width: 69px;
        height: 4px;
        border-radius: 4px;
        background: #ffffff40;

        button {
          height: 4px;
          opacity: 0.25;
        }

        &.slick-active {
          width: 160px;

          button {
            background: $theme-secondary;
            opacity: 1;
          }
        }
      }
    }
  }

  &.auths-forgot-password {
    background: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)),
      url("../images/forgot-password-background.png") no-repeat center;
    background-size: cover;
    background-blend-mode: color;
    min-height: 100vh;

    .auth-left-div {
      max-width: 700px;
      margin: 40px auto;

      .basic-details-auth {
        padding-bottom: 30px;
        h4 {
          font-size: 56px;
        }
        p {
          padding: 0 20px;
        }
      }

      .basic-details-confirm {
        p {
          font-weight: 400;
          font-size: 25px;
          line-height: 150%;
          text-align: center;
          color: $white-color;
          margin-bottom: 30px;
        }
      }
    }

    .footer-forgot-pwd {
      bottom: 20%;
      @include screen("mobile") {
        margin-top: 20px;
      }
      p {
        a {
          color: $theme-color;
        }
      }
    }
  }

  &.auths-main-profile {
    .auth-left-div {
      padding: 60px 60px 60px;

      .progress-steps-div {
        display: flex;
        align-items: center;
        padding-top: 85px;
        max-width: 300px;

        .ant-progress-outer {
          padding: 0;
        }

        .ant-progress {
          margin-bottom: 0;
          .ant-progress-text {
            display: none;
          }
          .ant-progress-inner {
            background-color: $gray-rgba-6;

            .ant-progress-bg {
              background-color: $theme-secondary;
            }
          }
        }

        .steps-count {
          font-weight: 700;
          font-size: 18px;
          line-height: 170%;
          color: $grasS;

          span {
            &.active-count {
              font-size: 26px;
              font-weight: 600;
              color: $theme-secondary;
            }
            &.normal-count {
              font-size: 18px;
              font-weight: 300;
            }
          }
        }
      }

      .basic-details-auth {
        padding: 30px 30px 15px;
      }

      .plan-card-row {
        background: $theme-color;
        border: 1px solid $gray-rgba-7;
        box-shadow: 0 12px 16px -4px rgba(16, 24, 40, 0.08),
          0px 4px 6px -2px rgba(16, 24, 40, 0.03);
        border-radius: 16px;
        padding: 45px 15px;
        h5 {
          font-weight: 800;
          font-size: 48px;
          letter-spacing: -0.02em;
          color: $white-color;
          margin-bottom: 15px;
        }

        p {
          font-weight: 600;
          font-size: px;
          color: $theme-secondary;
        }

        ul {
          padding: 15px 0;
          li {
            font-weight: 600;
            font-size: 16px;
            color: $white-color;
            padding: 8px 0;
            line-height: 24px;
            img {
              margin-right: 10px;
            }
          }
        }

        .ant-btn.get-started-btn {
          background: $black-color;
          box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
          border-radius: 8px;
          height: 48px;
          font-weight: 700;
          font-size: 16px;
          line-height: 24px;
          color: $white-color;
          text-align: center;
          width: 100%;
          border: 0;
        }
        .ant-btn.cancel-btn {
          background-color: rgb(203 64 64);
          box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
          border-radius: 8px;
          height: 48px;
          font-weight: 700;
          font-size: 16px;
          line-height: 24px;
          color: $white-color;
          text-align: center;
          width: 100%;
          border: 0;
        }
      }
    }
  }
}

.ant-input:placeholder-shown {
  text-overflow: ellipsis;
  font-weight: 300 !important;
}

.auth-form-container {
  max-width: 80%;
  @include screen("desktop") {
    max-width: 597px;
  }
}

.flag {
  font-family: "Noto Color Emoji", sans-serif !important;
  margin-right: 10px;
}
