.navbar_container {
  position: sticky;
  top: 0;
  background-color: blue;
  height: 100px;
}
.subscription-container {
  display: flex;
  flex-direction: column;
  background-color: black;
  width: 100%;
  height: 100vh;
  overflow-y: auto;
}
.subscription-main-container {
  color: #fff;
  /* border: 1px solid red; */
  width: 50%;
  height: -moz-fit-content;
  height: fit-content;
  padding: 1rem;
  margin: 4rem auto;
  display: flex;
  flex-direction: column;
  gap: 3rem;
}
.first-subs-content {
  height: 20vh;
  display: flex;
  /* background-color: yellow; */
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  padding: 0px 3rem;
}
.second-subs-content {
    background-color: #0093ff;
    border-radius: 10px;
    padding: 2rem;
    margin: 0px auto;
    width: 464px;
}
.inner-main-card {
  display: flex;
  width: 100%;
  gap: 1rem;
  align-items: center;
  padding: 0.5rem;
}
.subs-title {
  font-size: 2.5rem;
  text-align: center;
  font-weight: 700;
}
.subs-text {
  text-align: center;
  color: #8b8b8b;
  width: 370px;
}
.subs-name {
  text-align: center;
  color: #afff06;
  margin-bottom: 1rem;
  font-weight: 800;
}
.subs-check-icon {
  width: fit-content;
  cursor: pointer;
  background-color: #afff06;
  border-radius: 50%;
  padding: 0px 5px;
  color: #000;
}
.subs-button {
  padding: 10px;
  cursor: pointer;
  background-color: #000;
  color: #fff;
  border-radius: 5px;
  font-weight: 600;
  text-align: center;
  margin-top: 1.5rem;
}
