@import "./theme.scss";
nav.ant-breadcrumb.iON-breadcrumb {
  color: #d4d4d4;
  font-size: 18px;
  font-family: "Manrope";
  font-weight: 400;
  ol {
    li.ant-breadcrumb-separator {
      color: #d4d4d4;
    }
    li {
      cursor: pointer;
      &:last-child {
        span {
          color: #afff06;
          font-weight: 600;
          font-family: "Manrope";
          background: none !important;
        }
      }
    }
  }
}
.category-image-container {
  max-height: 300px;
  max-width: 300px;
  position: relative;
  height: 100%;
  width: 100%;
  @include screen("mobile") {
    max-height: 220px;
  }
  img.menu-icon {
    position: absolute;
    right: 5px;
    top: 10px;
    cursor: pointer;
    z-index: 9;
  }
  img.cover-image {
    border-radius: 10px;
    height: 100%;
    max-height: 300px;
    max-width: 300px;
    min-height: 300px;
    object-fit: cover;
    width: 100%;
    @include screen("mobile") {
      max-height: 220px;
      min-height: 220px;
    }
  }
  .image-label-top {
    background: linear-gradient(
      180deg,
      rgb(0 0 0 / 65%),
      #00000026,
      rgba(0, 0, 0, 0.11),
      transparent
    );
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
  }
  .image-label {
    background: linear-gradient(
      1turn,
      rgba(0, 0, 0, 0.929),
      transparent,
      rgba(0, 0, 0, 0.11),
      transparent
    );
    bottom: -1px;
    color: #fff;
    font-size: 16px;
    font-weight: 600;
    height: 100%;
    left: 0;
    padding: 5px;
    position: absolute;
    text-align: left;
    width: 100%;
    div {
      bottom: 20px;
      left: 20px;
      position: absolute;
    }
  }
  .ant-dropdown-trigger {
    position: absolute;
    right: 2px;
    top: 5px;
    cursor: pointer;
    z-index: 9;
  }
}
.ant-dropdown.img-menu {
  ul.ant-dropdown-menu {
    li.ant-dropdown-menu-item {
      background: none !important;
      padding: 0px !important;
      span {
        button {
          // color: #db524e !important;
          font-size: 18px;
          display: flex;
          align-items: center;
        }
      }
    }
  }
}
