.navbar_container {
  position: sticky;
  top: 0;
  background-color: blue;
  height: 100px;
}
.inner-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 1rem;
}
.active {
  // border: 3px solid #fff;
}
.cardDetails_container {
  display: flex;
  flex-direction: column;
  background-color: black;
  width: 100%;
  height: 100vh;
  overflow-y: auto; /* Enable vertical scrolling */
}
.check-icon-hidden {
  display: none;
}
.check-icon {
  position: absolute;
  right: 5%;
  top: 5%;
  cursor: pointer;
  border-radius: 50%;
  padding: 0px 0px;
}
.circle-icon {
  display: inline-block;
  width: 40px; /* Adjust the size of the circle */
  height: 40px; /* Adjust the size of the circle */
  border-radius: 50%;
  background-color: #ccc; /* Placeholder background color */
  text-align: center;
  line-height: 40px;
  cursor: pointer;
  margin-right: 10px; /* Adjust spacing as needed */
}

.circle-icon.selected {
  background-color: #007bff; /* Selected circle background color */
}

.circle-placeholder {
  display: inline-block;
  width: 21px;
  border-radius: 50%;
  height: 21px;
  border: 2px solid #fff;
}
.icon-main-container {
  display: flex;
  flex-direction: column;
  /* background-color: red; */
  align-items: center;
  justify-content: center;
}
.delete-icon {
  position: absolute;
  left: 5%;
  top: 5%;
  cursor: pointer;
  border-radius: 50%;
  padding: 0px 5px;
}

.delete-filled-icon {
  color: #fff;
  transition: color 0.3s;
}

.delete-icon:hover .delete-filled-icon {
  color: red;
}
.card-desc {
  /* background-color: yellow; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-weight: 500;
  font-size: 12px;
  gap: 10px;
  color: #909091;
  align-items: center;
  margin-bottom: 3rem;
}

.card-heading {
  font-weight: 800;
  font-size: 42px;
  color: #fff;
}
.card-description {
  font-family: "Manrope";
  font-size: 20px;
  font-weight: 500;
}
.card-second-container {
  width: 100%;
  display: flex;
  justify-content: center; /* Start the content from the left */
  align-items: center;
  overflow-y: auto; /* Hide vertical overflow */
  white-space: nowrap; /* Prevent cards from wrapping to the next line */
  position: relative;
  flex-wrap: wrap;

  &::-webkit-scrollbar {
    height: 1px;
  }
}
.previous-arrow {
  margin-right: 20px; /* Adjust the spacing between icons */
}

.car-icon-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem;
}
.arrow-icon {
  display: flex;
  align-items: flex-start; /* Align arrow icon to the top */
}

.centered-car-icon {
  flex: 1;
  display: flex;
  justify-content: center;
  /* Add other styling for the car icon */
}
.img-box {
  // padding: 2.5rem;
  // width: 420px;
  // // background-color: #d4d4d414;
  // border-radius: 3rem;
}
// .button {
//   border: 1px dashed #9e9b9b;
//   cursor: pointer;
//   padding: 2.5rem;
//   width: 420px;
//   background-color: rgba(212, 212, 212, 0.0784313725);
//   border-radius: 3rem;
//   height: 20rem;
// }
.add-cred-card-button {
  border: 1px dashed #9e9b9b;
  cursor: pointer;
  width: 398.148px;
  height: 246px;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  font-size: 30px;
  color: #fff;
  margin-top: 17px;
  margin-left: 10px;
}
.active-container {
  position: relative;
}
