@media only screen and (min-width: 320px) and (max-width: 767px) {
  .auths-main-fluid .auth-left-div {
    padding: 30px 15px;
  }
  .auths-main-fluid .auth-right-div {
    display: none;
  }
  .enduseragreement-content {
    padding: 0 2.2rem !important;
  }
  .auths-main-fluid .auth-left-div .basic-details-auth h4 {
    font-size: 26px;
  }
  .auths-main-fluid .auth-left-div .basic-details-auth p,
  .common-form .ant-form .ant-form-item .auth-forgot-pwd,
  .auths-main-fluid .auth-left-div .auth-footer-details p {
    font-size: 14px;
    margin-bottom: 0;
  }
  .common-form
    .ant-form
    .ant-form-item
    .ant-form-item-control
    .ant-form-item-control-input
    .ant-form-item-control-input-content
    .ant-input,
  .common-form
    .ant-form
    .ant-form-item
    .ant-form-item-control
    .ant-form-item-control-input
    .ant-form-item-control-input-content
    .ant-input-password,
  .ant-btn.theme-common-btn {
    height: 55px;
  }
  .common-form .ant-form .ant-form-item {
    margin-bottom: 15px;
  }
  .auths-main-fluid .auth-left-div .auth-footer-details,
  .auths-main-fluid.auths-forgot-password .auth-left-div .basic-details-auth p {
    padding: 0;
  }

  .auths-main-fluid.auths-forgot-password .auth-left-div {
    padding: 45px 30px;
  }
  .auths-main-fluid.auths-forgot-password .auth-left-div .basic-details-auth {
    padding: 75px 15px 60px;
  }
  .common-form.common-form-forgot-pwd .ant-form .ant-form-item {
    margin-bottom: 30px;
  }

  .auths-main-fluid.auths-main-profile .auth-left-div {
    padding: 30px 15px;
  }
  .auths-main-fluid.auths-main-profile .auth-left-div .progress-steps-div {
    padding-top: 30px;
  }
  .auths-main-fluid.auths-main-profile .auth-left-div .basic-details-auth {
    padding: 20px 15px 30px;
  }
  .common-form
    .ant-form
    .ant-form-item
    .ant-form-item-control
    .ant-form-item-control-input
    .ant-form-item-control-input-content
    .ant-input.textArea-address {
    height: 85px !important;
  }
  .common-form
    .ant-form
    .ant-form-item
    .active-social-link-div
    .social-medial-detail-div {
    font-size: 13px;
    width: 75%;
  }
  .common-form .ant-form .ant-form-item .active-social-link-div {
    height: 55px;
  }
  .common-form
    .ant-form
    .ant-form-item
    .active-social-link-div
    .social-status-div
    img {
    height: 40px;
  }
  .auths-main-fluid.auths-main-profile
    .auth-left-div
    .progress-steps-div
    .steps-count {
    font-size: 15px;
  }
  .auths-main-fluid.auths-main-profile .auth-left-div .plan-card-row {
    padding: 30px 0;
  }
  .auths-main-fluid.auths-main-profile .auth-left-div .plan-card-row h5 {
    font-size: 32px;
  }
  .auths-main-fluid.auths-main-profile .auth-left-div .plan-card-row p {
    font-size: 16px;
  }
  .auths-main-fluid.auths-main-profile .auth-left-div .plan-card-row ul li {
    font-size: 14px;
    padding: 6px 0;
  }
  .ant-btn.skip-btn {
    font-size: 16px;
    top: 15px;
    right: 15px;
    width: auto;
    z-index: 9;
  }
  .common-form .ant-form .small-text-info {
    bottom: 12px;
    font-size: 11px;
  }
  .common-form .multiple-check-div {
    margin: 0 0 30px;
  }
  .common-form .multiple-check-div .ant-checkbox-group ul {
    margin-bottom: 0;
  }
  .common-form .multiple-check-div .ant-checkbox-group ul li {
    margin-top: 10px;
  }
  .common-form
    .multiple-check-div
    .ant-checkbox-group
    ul
    li
    .ant-checkbox-wrapper {
    height: 45px;
    padding: 5px 15px;
    font-size: 13px;
  }
  .common-form
    .multiple-check-div
    .ant-checkbox-group
    ul
    li
    .ant-checkbox-wrapper.ant-checkbox-wrapper-checked {
    padding: 5px 28px 5px 6px;
  }
  .common-form .upload-img-tag .ant-upload-wrapper .ant-upload .ant-btn {
    height: 50px;
    padding: 10px 20px;
    font-size: 16px;
  }
  .common-form
    .multiple-check-div
    .ant-checkbox-group
    ul
    li
    .ant-checkbox-wrapper.ant-checkbox-wrapper-checked
    img {
    right: 6px;
  }
  .common-form .upload-img-tag,
  .common-form .images-upload-list {
    margin-bottom: 30px;
  }
  .common-form .images-upload-list ul {
    display: inline-block !important;
  }
  .common-form .images-upload-list ul li.list-inline-item:not(:last-child) {
    margin-right: 0.4rem;
  }
  .common-form .images-upload-list ul li .images-section {
    width: 60px;
    height: 60px;
  }
  .ant-btn.close-btn-upload-img {
    height: 60px;
    width: 60px;
    margin-left: 0;
    margin-top: 10px;
  }
  .ant-btn.add-more-btn-upload {
    margin-top: 10px;
    height: 60px;
  }
  .ant-btn.theme-common-btn.subscribe-btn {
    margin-top: 0;
  }
  .ant-btn.skip-btn.skip-btn-bottom {
    margin-top: 30px;
  }
  .home-main-banner-fluid {
    padding: 25% 15px 45px;
  }
  .home-main-banner-fluid .slider-div-home {
    width: 100%;
  }
  .home-main-banner-fluid .slider-div-home .slider-counter-div {
    top: -75px;
    left: 15px;
  }
  .home-main-banner-fluid .slider-div-home .slider-counter-div h6 {
    font-size: 14px;
    line-height: 20px;
  }
  .home-main-banner-fluid .slider-div-home .slider-counter-div ul {
    margin-bottom: 0;
  }
  .home-main-banner-fluid .slider-div-home .slider-counter-div ul li {
    font-size: 16px;
  }
  body .container-fluid .slick-dots,
  br {
    display: none !important;
  }
  .custom-header-main {
    height: 80px;
  }
  .right-tool-for-job-fluid .right-tool-img-div {
    padding-bottom: 0;
  }
  .right-tool-for-job-fluid .tools-job-details,
  .shops-highlights-fluid,
  .right-tool-for-job-fluid,
  .contact-us-fluid {
    padding: 45px 0;
  }
  .right-tool-for-job-fluid {
    padding: 0 0 45px;
  }
  .right-tool-for-job-fluid .tools-job-details h2 {
    font-size: 26px;
  }
  .right-tool-for-job-fluid .tools-job-details p {
    font-size: 14px;
    margin-bottom: 30px;
  }
  .right-tool-for-job-fluid .job-details-card .job-details-img-div img {
    height: auto;
  }
  .right-tool-for-job-fluid .job-details-card .job-details-list li {
    font-size: 16px;
    height: 70px;
    padding: 0 15px;
  }
  .right-tool-for-job-fluid .job-details-card .job-details-list li img {
    height: 30px;
  }
  .automotive-digital-documentation-details {
    padding-top: 0;
  }
  .automotive-digital-documentation-details .automotive-digital-img-div {
    width: 100%;
  }
  .automotive-digital-documentation-details .automotive-digital-details {
    width: 100%;
    position: relative;
    top: 0;
    padding-top: 75px;
  }
  .automotive-digital-documentation-details
    .automotive-digital-img-div
    .trusted-shops-worldwide-div {
    width: calc(100% - 30px);
    bottom: -50px;
    left: 15px;
    min-height: 105px;
  }
  .automotive-digital-documentation-details
    .automotive-digital-img-div
    .trusted-shops-worldwide-div
    h3 {
    font-size: 16px;
    margin-bottom: 10px;
  }
  .automotive-digital-documentation-details
    .automotive-digital-img-div
    .trusted-shops-worldwide-div
    .trusted-client-list
    .client-img-tag,
  .automotive-digital-documentation-details
    .automotive-digital-img-div
    .trusted-shops-worldwide-div
    .trusted-client-list
    .client-img-tag
    img,
  .automotive-digital-documentation-details
    .automotive-digital-img-div
    .trusted-shops-worldwide-div
    .trusted-client-list
    .client-total-count-div {
    width: 45px;
    height: 45px;
  }
  .automotive-digital-documentation-details
    .automotive-digital-img-div
    .trusted-shops-worldwide-div
    .trusted-client-list
    .client-total-count-div {
    font-size: 16px;
  }
  .automotive-digital-documentation-details .automotive-digital-details h2 {
    font-size: 26px;
  }
  .automotive-digital-documentation-details
    .automotive-digital-details
    h2
    .in-hand-span,
  .automotive-digital-documentation-details
    .automotive-digital-details
    h2
    .primary-span
    .documentation-span {
    padding-left: 0;
  }
  .automotive-digital-documentation-details
    .automotive-digital-details
    .automotive-digital-steps {
    display: block;
    padding-top: 30px;
  }
  .automotive-digital-documentation-details
    .automotive-digital-details
    .automotive-digital-steps
    .automotive-steps-card {
    width: 100%;
    height: 200px;
    padding: 15px;
    margin-bottom: 15px;
  }
  .automotive-digital-documentation-details
    .automotive-digital-details
    .automotive-digital-steps
    .automotive-steps-card
    h6 {
    font-size: 20px;
  }
  .common-heading h6 {
    font-size: 32px;
  }
  .common-heading {
    margin-bottom: 30px;
  }

  .our-community-fluid {
    padding: 30px 0 45px;
  }
  .our-community-fluid .community-heading {
    margin-bottom: 30px;
  }
  .our-community-fluid .community-heading h3 {
    font-size: 26px;
  }
  .our-community-fluid .arrow-with-count {
    top: -40px;
    right: 0;
  }
  .our-community-fluid .arrow-with-count ul li {
    font-size: 15px;
  }
  .community-slider-data {
    margin-bottom: 30px;
  }
  .our-community-fluid .community-slider-data .community-member-img {
    width: 100%;
    margin-bottom: 15px;
  }
  .our-community-fluid .community-slider-data .community-details-div {
    width: 100%;
    padding-left: 0;
  }
  .our-community-fluid .community-slider-data .community-details-div p {
    font-size: 14px;
  }
  .community-details-div ul li img {
    height: 20px;
  }
  .our-community-fluid .community-slider-data .community-details-div h6 {
    font-size: 18px;
  }
  .our-community-fluid .facebook-update-main-row {
    padding: 15px 0 45px;
  }
  .our-community-fluid .facebook-update-main-row .fb-update-heading h5 {
    font-size: 26px;
    margin-bottom: 20px;
  }
  .our-community-fluid
    .facebook-update-main-row
    .fb-update-heading
    .connect-fb-btn {
    height: 55px;
    width: 80%;
    font-size: 15px;
  }
  .our-community-fluid .facebook-update-main-row .fb-slider-row {
    position: relative;
    padding-left: 0;
    width: 100%;
    margin: 30px 0 0;
  }
  .our-community-fluid
    .facebook-update-main-row
    .fb-slider-row
    .fb-slide-inner
    img {
    height: 250px;
    width: 100%;
    object-fit: cover;
  }
  .our-community-fluid
    .facebook-update-main-row
    .fb-slider-row
    .fb-slide-inner {
    height: auto;
  }
  .our-community-fluid
    .facebook-update-main-row
    .fb-slider-row
    .fb-slide-inner
    > .col-12 {
    margin-bottom: 15px;
  }
  .our-community-fluid
    .facebook-update-main-row
    .fb-slider-row
    .fb-slide-inner:before {
    background: linear-gradient(-90deg, rgba(0, 0, 0, 0.4) 64.93%, #000 100%);
  }
  .our-community-fluid
    .facebook-update-main-row
    .fb-slider-row
    .fb-slide-inner
    .highlights-slider-content-overlay
    h5 {
    font-size: 18px;
    margin: 0;
  }
  .our-community-fluid .ion-app-details-card {
    margin-top: 0;
    padding: 30px 15px 0;
    min-height: 370px;
  }
  .our-community-fluid .facebook-update-main-row .arrow-with-count {
    bottom: -4%;
    right: 15px;
  }
  .our-community-fluid .ion-app-details-card h4 {
    margin-bottom: 15px;
    font-size: 26px;
  }
  .our-community-fluid .ion-app-details-card ul {
    padding-bottom: 30px;
  }
  .our-community-fluid .ion-app-details-card ul li img {
    height: 40px;
  }
  .our-community-fluid .ion-app-details-card .mockup-ios-div {
    top: 0;
    z-index: 1;
    left: -30px;
    position: relative !important;
  }
  .our-community-fluid .ion-app-details-card .mockup-android-div {
    right: 0;
    top: 0;
  }
  .our-community-fluid .ion-app-details-card .mockup-android-div img {
    height: 195px;
  }

  .footer-main-fluid .footer-row-1 ul li a {
    font-size: 14px;
    line-height: 25px;
  }
  .footer-main-fluid .footer-row-1 {
    padding: 30px 0;
  }
  .footer-main-fluid .footer-row-2 {
    padding: 20px 0;
  }
  .footer-main-fluid .footer-row-2 h5 {
    font-size: 22px;
    margin-bottom: 20px;
  }
  .footer-row-2 ul li img {
    height: 40px;
  }
  .footer-main-fluid .footer-row-3 {
    padding: 15px 0;
  }
  .footer-main-fluid .footer-row-3 p {
    padding-bottom: 5px;
  }
  .footer-main-fluid .footer-row-3 p,
  .footer-main-fluid .footer-row-3 ul li a {
    font-size: 14px;
    text-align: center;
  }
  .footer-main-fluid .footer-row-3 ul li a {
    font-size: 14px;
  }
  .contact-us-fluid .contact-form-row {
    padding: 30px 0;
    margin-bottom: 30px;
  }
  .contact-us-fluid .contact-form-row h1 {
    font-size: 26px;
    margin-bottom: 15px;
  }
  .contact-us-fluid .contact-form-details {
    padding-left: 15px;
  }
  .contact-us-fluid .contact-form-details h2 {
    font-size: 18px;
  }
  .contact-us-fluid .contact-form-details ul {
    margin-bottom: 20px;
  }
  .contact-us-fluid .contact-form-details ul li a {
    font-size: 14px;
  }
  .contact-us-fluid .contact-form-details h6 {
    font-size: 20px;
  }
  .contact-us-fluid .contact-form-details p {
    font-size: 16px;
  }
  .faq-heading-fluid {
    padding: 45px 0 30px;
  }
  .faq-heading-fluid h1 {
    font-size: 26px;
  }

  .faq-details-fluid {
    padding: 45px 0 20%;
  }
  .faq-details-fluid .faq-details-row {
    padding: 20px 0;
  }
  .faq-details-fluid .faq-details-row h5 {
    font-size: 18px;
    margin-bottom: 10px;
  }
  .faq-details-fluid .faq-details-row p,
  .faq-details-fluid .faq-details-row ul li {
    font-size: 14px;
  }
  .faq-details-fluid .faq-details-row h6 {
    font-size: 16px;
  }
  .faq-details-fluid .add-vehicle-row {
    padding: 45px 0 30px;
  }
  .faq-details-fluid .add-vehicle-row h2 {
    font-size: 26px;
  }
  .faq-details-fluid .add-vehicle-row p {
    font-size: 14px;
    margin: 15px 0;
  }
  .faq-details-fluid .add-vehicle-row h3 {
    font-size: 16px;
  }
  .faq-details-fluid .project-overview-row {
    padding: 30px 0 0;
  }
  .faq-details-fluid .project-overview-row .project-demo-card {
    height: auto;
    margin-bottom: 45px;
  }
  .faq-details-fluid
    .project-overview-row
    .project-demo-card
    .project-demo-details
    h5 {
    font-size: 15px;
  }
  .faq-details-fluid
    .project-overview-row
    .project-demo-card
    .project-demo-details
    p {
    font-size: 15px;
    margin: 5px 0;
  }
  .faq-details-fluid .few-picture-row h2 {
    font-size: 26px;
  }
  .faq-details-fluid .few-picture-row p {
    font-size: 15px;
  }
  .faq-details-fluid .few-picture-row .picture-app-flow-div img {
    right: -128px;
    height: 250px;
  }
  .faq-details-fluid .few-picture-row .app-flow-description {
    padding-top: 30px;
  }
  .faq-documentation-fluid {
    padding: 55% 0 14%;
    margin-top: -165px;
  }
  .faq-documentation-fluid .document-heading h3 {
    font-size: 26px;
  }
  .faq-documentation-fluid .document-heading p {
    font-size: 14px;
  }
  .document-details-inner .col-12.d-flex.align-items-center {
    display: inline-block !important;
  }
  .faq-documentation-fluid .document-details-inner .docs-section-details ul {
    text-align: left !important;
  }
  .faq-documentation-fluid .document-details-inner .docs-section-details,
  .faq-documentation-fluid .document-details-inner .docs-section-img-div {
    width: 100%;
  }
  .faq-documentation-fluid .document-details-inner .docs-section-img-div {
    padding: 30px 0;
    text-align: center;
  }
  .faq-documentation-fluid .document-heading {
    padding-bottom: 20px;
  }
  .faq-documentation-fluid .document-details-inner .docs-section-details ul li {
    font-size: 13px;
    padding-bottom: 10px;
  }
  .faq-documentation-fluid .document-details-inner .docs-section-img-div img {
    height: 350px;
  }
  .faq-details-fluid.faq-des-fluid {
    padding-top: 15%;
    margin-top: -30px;
    padding-bottom: 60px;
  }
  .faq-details-fluid.faq-des-fluid .signup-btn-div .ant-btn {
    height: 50px;
    padding: 11px 60px;
    font-size: 15px;
  }
  .faq-details-fluid.faq-des-fluid
    .project-overview-update
    .project-demo-update.demo-row-1 {
    top: 0;
  }
  .faq-details-fluid.faq-des-fluid .add-vehicle-row ul {
    display: block;
  }
  .faq-details-fluid.faq-des-fluid .add-vehicle-row ul li {
    font-size: 13px;
  }
  .faq-details-fluid.faq-des-fluid
    .project-overview-update
    .project-demo-update
    .project-demo-details {
    width: 75%;
  }
  .faq-details-fluid.faq-des-fluid
    .project-overview-update
    .project-demo-update
    .project-img {
    height: 350px;
  }
  .faq-details-fluid.faq-des-fluid .regards-row-main .regards-big-img img {
    height: auto;
  }
  .faq-details-fluid.faq-des-fluid .regards-row-main {
    padding: 0 0 15px;
  }
  .faq-details-fluid.faq-des-fluid .regards-row-main .regards-details-row p {
    font-size: 14px;
    line-height: 21px;
  }
  .faq-details-fluid.faq-des-fluid .regards-row-main .regards-details-row {
    position: relative;
    right: 0;
    top: 0;
    padding-top: 45px;
    width: 100%;
  }
  .faq-details-fluid.faq-des-fluid
    .regards-row-main
    .regards-details-row
    .regards-sign-div
    img {
    height: 40px;
  }
  .toggle-btn {
    position: absolute;
    top: 0;
    right: 0;
    background: transparent;
    border: none;
    padding: 0;
    height: 25px;
    color: #ffffff;
    width: auto;
    bottom: 0;
    margin: auto;
    font-size: 24px;
  }
  .toggle-btn.ant-btn-default:not(:disabled):hover {
    color: #ffffff;
    border-color: transparent;
  }
  .home-main-banner-fluid .slider-div-home .slider-bottom-slick li,
  .home-main-banner-fluid
    .slider-div-home
    .slider-bottom-slick
    li
    .ant-btn
    img {
    height: 50px;
  }
  .home-main-banner-fluid
    .slider-div-home
    .slider-bottom-slick
    li
    .ant-btn
    img {
    object-fit: cover;
  }
  .our-community-fluid .community-slider-data .community-member-img img {
    height: 200px;
  }
  .sidebar-custom {
    display: block;
    position: fixed;
    z-index: 99;
    left: -100%;
    top: 0;
    height: 100vh;
    overflow: auto;
    width: 100%;
    background: #000000;
    transition: 0.3s ease all;
    padding: 45px 15px;
  }
  .sidebar-custom.sidebar-toggle {
    left: 0;
  }
  .close-btn-sidebar {
    position: absolute;
    top: 0;
    right: 15px;
    font-size: 30px;
    color: #ffffff;
  }
  .sidebar-custom ul li a {
    color: #ffffff;
    font-size: 16px;
    width: 100%;
    text-transform: uppercase;
    font-family: Oswald, sans-serif;
    font-weight: 600;
    padding: 8px 0;
    display: inline-block;
  }
  .sidebar-custom ul li a:hover,
  .sidebar-custom ul li a.active {
    color: #afff06;
  }
  .sidebar-custom .auth-btn-ul {
    margin-top: 30px;
  }
  .sidebar-custom .auth-btn-ul li .ant-btn {
    height: 50px;
    width: 125px;
    padding: 8px 15px;
    border-radius: 80px;
    font-size: 16px;
    font-family: Oswald, "sans-serif";
    border: 2px solid #ffffff;
    color: #ffffff;
    background: transparent;
    font-weight: 600;
  }
  .sidebar-custom .auth-btn-ul li .ant-btn:hover {
    background: #ffffff;
    color: #0093ff;
  }
  .sidebar-custom .auth-btn-ul li .ant-btn.sign-up-btn {
    border-color: #0093ff;
    background: #0093ff;
    color: #ffffff;
  }
  .sidebar-custom .auth-btn-ul li .ant-btn.sign-up-btn:hover {
    background: transparent;
    color: #0093ff;
  }
  .categories-main-fluid {
    padding: 30px 0;
  }
  .categories-main-fluid .categories-row-heading h1 {
    font-size: 28px;
    padding-bottom: 20px;
  }
  .ant-btn.grid-list-view-btn {
    width: auto;
    height: 48px;
    padding: 10px 15px;
    font-size: 14px;
  }
  .ant-btn.add-restoration-btn {
    padding: 10px;
    height: 48px;
    font-size: 14px;
  }
  .categories-main-fluid .categories-row-heading {
    margin-bottom: 30px;
  }
  .categories-main-fluid .categories-row-card .category-img-div img,
  .categories-main-fluid
    .categories-row-card.categories-row-grid
    .category-img-div
    img {
    height: 170px;
    object-fit: cover;
  }
  .categories-main-fluid .categories-row-card .category-details-div {
    padding: 15px 15px 0;
  }
  .categories-main-fluid .categories-row-card .category-details-div h5 {
    font-size: 20px;
  }
  .categories-main-fluid .categories-row-card .category-details-div p {
    font-size: 14px;
  }
  .categories-main-fluid
    .categories-row-card
    .category-details-div
    .product-details-inner
    ul
    li {
    font-size: 15px;
  }
  .category-img-mob {
    position: absolute;
    bottom: 5px;
    right: 15px;
  }
  .categories-main-fluid
    .categories-row-card.categories-row-grid
    .category-QR-div {
    position: absolute;
    bottom: 5px;
    right: 15px;
  }
  .categories-main-fluid
    .categories-row-card
    .category-img-div
    .category-img-mob
    img,
  .categories-main-fluid
    .categories-row-card.categories-row-grid
    .category-QR-div
    img {
    height: 55px;
    width: 55px;
  }
  .profile-mobile-div {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 40px;
    margin: auto;
    height: 50px;
  }
  .profile-details-btn.ant-btn {
    width: 50px;
    height: 50px;
    margin-left: 0;
  }
  .ant-dropdown.profile-dropdown
    .ant-dropdown-menu
    .ant-dropdown-menu-item
    .ant-dropdown-menu-title-content
    a,
  .ant-dropdown.profile-dropdown
    .ant-dropdown-menu
    .ant-dropdown-menu-item
    .ant-dropdown-menu-title-content
    .ant-btn.logout-btn {
    height: 50px;
    font-size: 15px;
  }
  .ant-dropdown.profile-dropdown .ant-dropdown-menu {
    min-height: auto;
  }
  .ant-dropdown.profile-dropdown {
    min-width: 230px !important;
  }
}

@media only screen and (min-width: 320px) and (max-width: 767px) and (orientation: landscape) {
  .footer-main-fluid .footer-row-2 h5 {
    margin-bottom: 0;
  }
  .footer-row-2 ul li img {
    height: 30px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1200px) {
  .auths-main-fluid.auths-forgot-password .auth-left-div {
    padding: 60px;
  }
  .auths-main-fluid.auths-forgot-password .auth-left-div .basic-details-auth p {
    padding: 0 45px;
  }
  .auths-main-fluid .auth-left-div {
    padding: 30px 15px;
  }
  .auths-main-fluid .auth-left-div .auth-footer-details {
    padding: 0 0 30px;
  }
  .auths-main-fluid .auth-left-div .auth-footer-details p,
  .auths-main-fluid .auth-left-div .basic-details-auth p {
    margin-bottom: 0;
  }
  .auths-main-fluid .auth-left-div .basic-details-auth h4 {
    font-size: 30px;
  }
  .auths-main-fluid .auth-right-div .auth-carousel-effect p {
    font-size: 14px;
  }
  .contact-us-fluid .contact-form-row h1 {
    font-size: 32px;
  }
  .contact-us-fluid .contact-form-details h2 {
    font-size: 28px;
  }
  .contact-us-fluid .contact-form-details ul li a,
  .contact-us-fluid .contact-form-details p {
    font-size: 18px;
  }
  .contact-us-fluid .contact-form-details h6 {
    font-size: 22px;
  }
  .footer-main-fluid .footer-row-2 h5 {
    font-size: 28px;
    margin-bottom: 0;
  }
  .footer-row-2 ul li img {
    height: 45px;
  }
  .footer-main-fluid .footer-row-3 p {
    font-size: 16px;
  }
  .footer-main-fluid .footer-row-3 ul li a {
    font-size: 15px;
  }
  .footer-main-fluid .footer-row-3 {
    padding: 20px 0;
  }
  .footer-main-fluid .footer-row-2,
  .footer-main-fluid .footer-row-1 {
    padding: 30px 0;
  }
  .footer-main-fluid .footer-row-1 ul li a {
    font-size: 14px;
  }

  .custom-header-main .nav-menu-list li a {
    font-size: 18px;
  }
  .custom-header-main .auth-btn-ul li .ant-btn {
    height: 50px;
    width: 115px;
    font-size: 18px;
  }
  .faq-heading-fluid h1 {
    font-size: 36px;
  }

  .faq-details-fluid {
    padding: 60px 0 15%;
  }
  .faq-details-fluid .faq-details-row {
    padding: 20px 0;
  }
  .faq-details-fluid .faq-details-row h5 {
    font-size: 22px;
  }
  .faq-details-fluid .faq-details-row p,
  .faq-details-fluid .faq-details-row ul li {
    font-size: 16px;
  }
  .faq-details-fluid .faq-details-row h6 {
    font-size: 18px;
  }
  .faq-details-fluid .add-vehicle-row {
    padding: 60px 0 30px;
  }
  .faq-details-fluid .add-vehicle-row h2 {
    font-size: 36px;
  }
  .faq-details-fluid .add-vehicle-row p {
    font-size: 16px;
  }
  .faq-details-fluid .add-vehicle-row h3 {
    font-size: 24px;
  }
  .faq-details-fluid
    .project-overview-row
    .project-demo-card
    .project-demo-details
    h5 {
    font-size: 18px;
  }
  .faq-details-fluid .few-picture-row h2 {
    font-size: 36px;
  }
  .faq-details-fluid .few-picture-row .picture-app-flow-div img {
    right: -41.9%;
    height: 500px;
  }
  .faq-details-fluid .few-picture-row p {
    font-size: 16px;
  }
  .faq-documentation-fluid {
    padding: 28% 0 10%;
    margin-top: -200px;
  }
  .faq-documentation-fluid .document-heading h3 {
    font-size: 36px;
  }
  .faq-documentation-fluid .document-heading p {
    font-size: 16px;
  }
  .document-details-inner .col-12.d-flex.align-items-center {
    display: block !important;
  }
  .faq-documentation-fluid .document-details-inner .docs-section-details {
    width: 100%;
  }
  .faq-documentation-fluid .document-details-inner .docs-section-details ul {
    text-align: left !important;
  }
  .faq-documentation-fluid .document-details-inner .docs-section-img-div {
    width: 100%;
    text-align: center;
    padding: 30px 15px;
  }
  .faq-documentation-fluid .document-details-inner .docs-section-img-div img {
    height: 500px;
  }
  .faq-documentation-fluid .document-details-inner .docs-section-details ul li {
    font-size: 16px;
    padding-bottom: 20px;
  }
  .faq-details-fluid.faq-des-fluid {
    padding-top: 13%;
    margin-top: -75px;
  }
  .faq-details-fluid.faq-des-fluid .signup-btn-div .ant-btn {
    padding: 10px 56px;
    font-size: 18px;
  }
  .faq-details-fluid.faq-des-fluid .project-overview-update {
    padding: 15% 0 0;
  }
  .faq-details-fluid.faq-des-fluid
    .project-overview-update
    .project-demo-update
    .project-img {
    height: 245px;
  }
  .faq-details-fluid.faq-des-fluid
    .project-overview-update
    .project-demo-update
    .project-demo-details
    p {
    font-size: 12px;
  }
  .faq-details-fluid.faq-des-fluid .regards-row-main {
    padding: 0 0 90px;
  }
  .faq-details-fluid.faq-des-fluid .regards-row-main .regards-details-row {
    padding-top: 0;
    height: 337px;
    overflow: auto;
  }
  .faq-details-fluid.faq-des-fluid .regards-row-main .regards-details-row p {
    font-size: 14px;
    line-height: 21px;
  }
  .faq-details-fluid.faq-des-fluid
    .regards-row-main
    .regards-details-row
    .regards-sign-div
    img {
    height: 45px;
  }
  .faq-details-fluid.faq-des-fluid .regards-row-main .regards-big-img img {
    height: auto;
  }
  br {
    display: none;
  }
  .home-main-banner-fluid .slider-div-home .slider-counter-div h6 {
    font-size: 15px;
  }
  .home-main-banner-fluid .slider-div-home .slider-counter-div {
    top: -80px;
  }
  .home-main-banner-fluid .slider-div-home .slider-counter-div ul li {
    font-size: 16px;
  }
  .home-main-banner-fluid .slider-div-home,
  .home-main-banner-fluid .right-side-content-div {
    width: 100%;
  }
  .home-main-banner-fluid .right-side-content-div h1 {
    font-size: 46px;
    right: 0;
    top: 0;
    width: 100%;
    padding-top: 30px;
  }

  .home-main-banner-fluid .row {
    margin: 0;
  }
  .home-main-banner-fluid .slider-div-home .slider-bottom-slick li,
  .home-main-banner-fluid
    .slider-div-home
    .slider-bottom-slick
    li
    .ant-btn
    img {
    height: 105px;
  }
  .home-main-banner-fluid .right-side-content-div .social-media-link {
    margin-top: 30px;
  }
  .right-tool-for-job-fluid .tools-job-details {
    padding: 30px 0;
  }
  .right-tool-for-job-fluid .tools-job-details h2,
  .automotive-digital-documentation-details .automotive-digital-details h2,
  .common-heading h6,
  .our-community-fluid .community-heading h3,
  .our-community-fluid .facebook-update-main-row .fb-update-heading h5,
  .our-community-fluid .ion-app-details-card h4 {
    font-size: 46px;
  }
  .right-tool-for-job-fluid .job-details-card {
    margin-top: 20px;
  }
  .automotive-digital-documentation-details .automotive-digital-details {
    width: 100%;
    position: relative;
    top: 0;
    padding-top: 75px;
  }
  .automotive-digital-documentation-details
    .automotive-digital-details
    h2
    .primary-span
    .documentation-span,
  .automotive-digital-documentation-details
    .automotive-digital-details
    h2
    .in-hand-span {
    padding-left: 0;
  }
  .automotive-digital-documentation-details
    .automotive-digital-details
    .automotive-digital-steps
    .automotive-steps-card
    h6 {
    font-size: 20px;
  }
  .automotive-digital-documentation-details
    .automotive-digital-details
    .automotive-digital-steps
    .automotive-steps-card {
    height: 220px;
  }
  .shops-highlights-fluid {
    padding: 45px 0;
  }
  .shops-highlights-fluid .highlights-slider-content-overlay {
    padding: 15px;
  }
  .shops-highlights-fluid .highlights-slider-content-overlay h5 {
    font-size: 26px;
  }
  .shops-highlights-fluid .slider-bottom-slick li {
    height: 96px;
  }
  .shops-highlights-fluid .slider-bottom-slick li .ant-btn img {
    height: 96px;
    object-fit: cover;
  }
  .community-slider-data {
    margin-bottom: 30px;
  }
  .our-community-fluid .facebook-update-main-row {
    padding: 75px 0 45px;
  }

  .our-community-fluid .facebook-update-main-row .arrow-with-count {
    bottom: -13%;
  }
  .our-community-fluid {
    margin-top: 60px;
    padding: 30px;
  }
  .our-community-fluid ul li img {
    height: 50px;
  }
  .our-community-fluid .ion-app-details-card .mockup-ios-div {
    top: 28px;
    z-index: 1;
    left: -70px;
    position: relative !important;
  }
  .our-community-fluid .ion-app-details-card .mockup-android-div {
    right: 0;
    top: 90px;
  }
  .our-community-fluid .community-slider-data .community-details-div h6 {
    font-size: 18px;
  }
  .our-community-fluid
    .facebook-update-main-row
    .fb-slider-row
    .fb-slide-inner
    .highlights-slider-content-overlay
    h5 {
    font-size: 18px;
  }
  .categories-main-fluid {
    padding: 30px 0;
  }
  .categories-main-fluid .categories-row-heading {
    margin-bottom: 30px;
  }
  .categories-main-fluid .categories-row-heading h1 {
    font-size: 30px;
  }
  .ant-btn.grid-list-view-btn {
    width: auto;
    height: 50px;
    padding: 10px 15px;
    font-size: 14px;
  }
  .ant-btn.add-restoration-btn {
    padding: 10px;
    height: 50px;
    font-size: 14px;
  }
  .categories-main-fluid .categories-row-card .category-img-div img {
    height: 175px;
    object-fit: cover;
  }
  .categories-main-fluid .categories-row-card .category-details-div {
    padding: 0 15px;
  }
  .categories-main-fluid .categories-row-card .category-details-div h5 {
    font-size: 22px;
  }
  .categories-main-fluid .categories-row-card .category-details-div p {
    font-size: 14px;
    margin-bottom: 5px;
  }
  .categories-main-fluid
    .categories-row-card
    .category-details-div
    .product-details-inner
    ul
    li {
    font-size: 14px;
  }
  .categories-main-fluid
    .categories-row-card
    .category-details-div
    .product-details-inner
    ul {
    padding-bottom: 5px;
  }
  .categories-main-fluid .categories-row-card .category-QR-div img {
    height: auto;
  }
  .categories-main-fluid
    .categories-row-card
    .category-details-div
    .product-details-inner
    ul
    li.list-inline-item:not(:last-child) {
    width: auto;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1200px) and (orientation: landscape) {
  .auths-main-fluid .auth-left-div {
    padding: 30px 45px;
  }
  .faq-details-fluid .few-picture-row .picture-app-flow-div img {
    right: -32.4%;
    height: 400px;
  }
  .faq-documentation-fluid {
    padding: 20% 0 10%;
    margin-top: -180px;
  }
  .document-details-inner .col-12.d-flex.align-items-center {
    display: flex !important;
  }
  .faq-documentation-fluid
    .document-details-inner
    .docs-section-details
    ul.text-end {
    text-align: right !important;
  }
  .faq-documentation-fluid .document-details-inner .docs-section-details {
    width: 40%;
  }
  .faq-documentation-fluid .document-details-inner .docs-section-img-div {
    width: 20%;
    padding: 0 15px;
  }
  .faq-documentation-fluid .document-details-inner .docs-section-img-div img {
    height: auto;
  }
  .faq-details-fluid.faq-des-fluid {
    margin-top: -100px;
  }
  .faq-details-fluid.faq-des-fluid
    .project-overview-update
    .project-demo-update
    .project-img {
    height: 255px;
  }
  .faq-details-fluid.faq-des-fluid .regards-row-main .regards-details-row {
    height: auto;
  }

  .right-tool-for-job-fluid .job-details-card .job-details-img-div img {
    height: auto;
  }
  .right-tool-for-job-fluid .job-details-card .job-details-list li {
    font-size: 18px;
    height: 60px;
  }
  .automotive-digital-documentation-details .automotive-digital-details {
    width: 60%;
    position: absolute;
    padding-top: 0;
  }
  .automotive-digital-documentation-details .automotive-digital-img-div {
    width: 550px;
  }
  .our-community-fluid .community-slider-data .community-details-div p {
    font-size: 14px;
  }
  .our-community-fluid
    .facebook-update-main-row
    .fb-update-heading
    .connect-fb-btn {
    width: 100%;
  }
  .our-community-fluid
    .facebook-update-main-row
    .fb-slider-row
    .fb-slide-inner
    img {
    height: 280px;
  }

  .our-community-fluid .ion-app-details-card .mockup-ios-div {
    top: -150px;
    position: absolute !important;
  }
  .our-community-fluid .ion-app-details-card .mockup-android-div {
    top: -135px;
  }
  .our-community-fluid .ion-app-details-card {
    margin-top: 44px;
  }
  .home-main-banner-fluid .slider-div-home {
    width: 550px;
  }
  .home-main-banner-fluid .right-side-content-div {
    /* width: calc(100% - 550px); */
    width: 100%;
  }
  .home-main-banner-fluid .right-side-content-div h1 {
    font-size: 50px;
    right: 90px;
    padding-top: 0;
    top: -80px;
  }
}
