@import "./ion-styles/media-queries";

$theme-color: #0093ff;
$theme-secondary: #afff06;
$white-color: #ffffff;
$black-color: #000000;
$grasS: #d4d4d4;
$gray-rgba-6: rgba(81, 84, 94, 0.51);
$gray-rgba-7: rgba(76, 123, 157, 0.25);
$white-gray-rgba: rgba(255, 255, 255, 0.48);

.bg-primary {
  background: $theme-color;
}
.img-slide-box {
  height: calc(100vh - 250px) !important;
  width: 100%;
  object-fit: contain;
  @include screen("mobile") {
    height: calc(100vh - 200px) !important;
  }
}
.image-modal {
  width: 100% !important;
  .ant-modal-content {
    padding: 0px !important;
    button.ant-modal-close {
      top: 10px;
      inset-inline-end: 20px;
      span.ant-modal-close-x {
        width: 30px;
        height: 30px;
        background: #00000091;
        border-radius: 50%;
      }
    }
  }
}
