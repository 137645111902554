@import "theme";

.contact-us-fluid {
  background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.24)),
    url("../images/contact-us-background-img.png") no-repeat;
  background-size: cover;
  padding: 60px 0;

  .contact-form-row {
    background: rgba(26, 27, 32, 0.45);
    backdrop-filter: blur(10px);
    padding: 45px 15px;
    h1 {
      color: $white-color;
      font-size: 42px;
      font-weight: 800;
      line-height: 130%;
      margin-bottom: 30px;
    }
  }

  .contact-form-details {
    padding-left: 15px;

    h2 {
      color: $white-color;
      font-size: 39px;
      font-family: Oswald, sans-serif;
      font-weight: 700;
      margin-bottom: 20px;
      line-height: 140%;
    }

    ul {
      margin-bottom: 30px;

      li {
        &.list-inline-item:not(:last-child) {
          margin-right: 3rem;
        }

        a {
          color: $white-color;
          font-size: 20px;
          font-weight: 400;

          &:hover {
            color: $theme-color;
          }
        }

        &.youtube {
          a {
            &:hover {
              color: #c4302b;
            }
          }
        }

        &.instagram {
          a {
            &:hover {
              background: -webkit-linear-gradient(
                #be54ff,
                #ff5da1,
                #ff735e,
                #ffd954
              );
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
            }
          }
        }

        &.linkedin {
          a {
            &:hover {
              color: #0a66c2;
            }
          }
        }
      }
    }

    h6 {
      color: $white-color;
      font-size: 30px;
      font-weight: 800;
      line-height: 150%;
    }

    p {
      font-size: 24px;
      line-height: 160%;
      font-weight: 400;

      a {
        color: $theme-secondary;
      }
    }
  }

  .margin-top-contact {
    margin-top: 15px;
  }
}
